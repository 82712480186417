import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";

import * as Yup from "yup";
import Select from "react-select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { useRef } from "react";
import { getClusterDetails, listClusterDetails, SaveClusterDetails } from "../../services/api.service";
// import TimezoneSelect from 'react-timezone-select'
export default function ClusterUsage(props) {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [loading, setloading] = useState(false);
  const [clusterList, setClusterList] = useState([]);
  const [search, setSearch] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [selectedConfigData, setSelectedConfigData] = useState(null);
  const [clusterCount, setClusterCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [newUser, setnewUser] = useState(undefined);
  const searchRef = useRef('');


  // useMemo(() => {
  //   const tzValue = tz.value ?? tz;
  //   setDatetime(datetime.goto(tzValue));
  // }, [tz]);
  useEffect(() => {
    console.log(clusterList,"clusterList");
   
    // values = ""
  }, [clusterList]);


  useEffect(() => {
    const getClusterConfigs = async () => {
      try {
        setloading(true);
        let filter = {
            resource_group: "Content-Pro",
            cluster_name: "contentprok8scluster"
        }

        const response = await listClusterDetails(filter);
        console.log(response.data.Body);
        if(response.data && response.data.Body) {
          setClusterList(response.data.Body);
        }
      } catch (error) {
        console.log(error);
      }
      searchRef.current = '';
      setloading(false);
    };
    getClusterConfigs();
  }, [props.createUserModal]);

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader-inner">
          <img src="../../../assets/img/loader.gif"></img>
        </div>
      </div>
    );
  };


 

  const listClusterData = (data) => {
    return (
      <div className="table-wraper">
        <div className="usage-list-warper template-listing cluster-listing">
          <div className="usage-block mb-3">
          <div className="usage-head-label text-center">Render Cluster Name</div>
            <div className="usage-head-label text-center">Count</div>
            <div className="usage-head-label text-center">CPU Usage</div>
            <div className="usage-head-label text-center">Memory</div>
            <div className="usage-head-label text-center">Vm Size</div>
            <div className="usage-head-label text-center">Min VM COunt</div>
            <div className="usage-head-label text-center">Max VM Count</div>
            <div className="usage-head-label text-center">provisioning_state</div>
            <div className="usage-head-label text-center">Mode</div>
            
          </div>
          {data != undefined &&
            Object.keys(data).map(function (configData, index) {
                console.log(configData);
              return (
                <div className="usage-block usg-sub-block mb-3">
                   <div className="usage-head-label text-center">
                   {configData}
                  </div>
                  <div className="usage-head-label text-center">
                    {data[configData].count!==undefined?data[configData].count:"--"}
                  </div>
                  <div className="usage-head-label text-center">
                    {data[configData].cpu!==undefined?data[configData].cpu:"--"}
                  </div>
                  <div className="usage-head-label text-center">
                    {data[configData].memory!==undefined?data[configData].memory:"--"}
                  </div>
                  <div className="usage-head-label text-center">
                      {data[configData].vm_size}</div>
                  
                  <div className="usage-head-label text-center">
                    {data[configData].min_scale_count}
                  </div>
                  <div className="usage-head-label text-center">
                    {data[configData].max_scale_count }
                  </div>
                  <div className="usage-head-label text-center">
                    {data[configData].provisioning_state }
                  </div>
                  <div className="usage-head-label text-center">
                    {data[configData].mode }
                  </div>
                  <div className="usage-head-label text-center">
                    {/* <button className="btn btn-sm btn-secondary d-inline-block m-auto" onClick={onEditClick.bind(null, configData)} type="button">Edit</button> */}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };


  return (
    <Modal
      isOpen={props.createUserModal}
      fade={true}
      centered
      className={DisplayMode ? "modal-dialog  modal-dialog-centered custom-modal  project-list full-view dark" :
      "modal-dialog  modal-dialog-centered custom-modal  project-list full-view"}
      // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
    >
      <ModalBody>
      {loading && <Loader />}
      <div className={DisplayMode ? "theme dark" : "theme"}>
       
      <div className="main-wrapper">
     
          <div className="template-wrapper pb-wrapper">
            <div className="d-flex">
                
              <div className="p-2 flex-fill total-template-count">
                <h2 className="h4">Cluster Usage</h2>
                
              </div>
              <button
                    onClick={() => {
                        
                          props.closePreview();
                        }
                    }
                    type="submit"
                    className="btn btn-outline-primary text-nowrap add-user-btn mb-2 me-3"
                    value="submit"
                    data-bs-toggle="modal"
                    data-bs-target="#add-user"
                  >
                       <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-name="Layer 2">
                      <g data-name="close">
                        <rect
                          width="24"
                          height="24"
                          transform="rotate(180 12 12)"
                          opacity="0"
                        />
                        <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                      </g>
                    </g>
                  </svg>
                   Close
                  </button>
            
          
            </div>
            
            {/* <EditSettings selectedConfigs={selectedConfigData} 
            updateConfigData={updateConfigData} clearSelectedConfig={clearSelectedConfigData} /> */}
            {
              clusterList!==undefined ? (
                listClusterData(clusterList)
              ) : (
                <div className="no-data">
                  <p>No data available</p>
                </div>
              )
            }
            {/* <div className="row pagination-holder mb-0 justify-content-center">
              <div className="col-auto">
                <Pagination
                  onChange={paginate}
                  page={currentPage}
                  shape='rounded'
                  variant="outlined"
                  color="primary"
                  count={Math.ceil(clusterCount/perPage)}
                />
              </div>
            </div> */}
          </div>
        </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
