
import React from 'react'

export default function pageLoader(props) {
    return (
        <>
        { props.showLoader?
        <div className="loader-wrapper video-loader">
            <div className="loader-inner">
                <img src="../../../assets/img/loader.gif"></img>
            </div>
        </div>:""}
        </>
    )
}
