export const HEADERFONT = "[H]"
export const PARAGRAPHFONT = "[P]"
export const BULLETFONT = "[B]"
export const FONT_URL = "https://oliveproduction-automation-storages.s3.amazonaws.com/Fonts/"
export const FONTFAMILY = "Roboto"
export const FONTSTYLE = "Roboto_100.ttf"
export const INIT_OST_FONT = FONT_URL + "Roboto/Roboto_100.ttf"
export const FONT_SIZE_H = 30
export const FONT_SIZE_P = 25
export const COUNT=0
export const TextColor = "#000000"
export const BgColor = "#FF9A9A"

// dashboard

export const ALLCATEGORY = "All Category"
export const LISTSTATUSALL = "All"