import { Tooltip, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getAssets } from "../../services/api.service";
import { showToaster } from "../../services/helper";
import Header from "../components/header/header";
import UploadAsset from "../components/UploadAsset";
import FallbackImg from "../../assets/img/demo-img.png";
import ImagePriview from "./project-priview/RightBar/ImagePriview";
import PreviewModal from "./project-priview/RightBar/PreviewModal";

function VisualAssets() {
  const history = useHistory();
  const location = useLocation();
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [loading, setLoading] = useState(false);
  const [assetsList, setAssetsList] = useState([]);
  const [search, setSearch] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(30);
  const [isPrivate, setIsPrivate] = useState(true);
  const [mediaType, setMediaType] = useState('IMAGE');
  const [isAddFile, setIsAddFile] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const searchTimerRef = useRef(null);

  useEffect(() => {
    setAssetsList([]);
    setStart(1)
    setEnd(30);
  }, [isPrivate, isSearch, mediaType]);

  useEffect(() => {
    const getAssetsList = async () => {
      try {
        if(!isPrivate && (!search || (search && !search.trim()))) {
          showToaster('Please enter search to show public items.', "warning");
          return;
        }
        setLoading(true);
        const searchArray = search.split(',').filter(s => s && s.trim())
        const response = await getAssets({
          media_type: mediaType,
          search_term: searchArray,
          start: start,
          end: end,
          private: isPrivate ? 1 : 0
        });
        if(response.status === 101 || response.status === 400) {
          // showToaster(response.message, "warning");
        } else if (Array.isArray(response.data)) {
          setAssetsList(assets => ([...assets, ...response.data]));
        } else if (Array.isArray(response.data.mediaurl)) {
          const assetsData = response.data.mediaurl.map(m => ({ mediaurl: m, thumbnailurl: m }))
          setAssetsList(assets => ([...assets, ...assetsData]));
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    getAssetsList();
  }, [isPrivate, isSearch, mediaType, start, end]);

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader-inner">
          <img src="../../../assets/img/loader.gif"></img>
        </div>
      </div>
    );
  };

  const onLoadMore = () => {
    setStart(end)
    setEnd(e => (e + e));
  }

  const onShowAsset = (asset, type, e) => {
    setSelectedAsset(asset);
    if(['IMAGE', 'ICON', 'LOGO'].indexOf(type)> -1) {
      setOpenImagePreview(true);
    } else if(type === 'VIDEO') {
      setOpenVideoPreview(true);
    }
  }

  const onImgLoadError = (e) => {
    e.target.src = FallbackImg;
  }

  const ListAssets = (data) => {
    return (
      <>
        <div className="tracking-wrapper">
          <div className="tracking-inner">
            {data.map((n, i) => {
              const assetImg = n.thumbnailurl ? n.thumbnailurl : (mediaType === 'IMAGE') ? n.mediaurl : FallbackImg;
              return (
                <div key={i} className="tracking-edit-list">
                  <img src={assetImg} onError={onImgLoadError} />
                  {(mediaType === 'VIDEO') && (
                    <div className="play-btn">
                      <img src="assets/img/play-btn.svg" />
                    </div>
                  )}
                  <div className="more-details">
                    <div className="invisible custom-check-2">
                      <input className="" type="checkbox" id={`html-${i}`} />
                      <label className="" for={`html-${i}`}></label>
                    </div>
                    <ul>
                      <li onClick={onShowAsset.bind(null, n, mediaType)}>
                        <img src="assets/img/Show.svg" />
                      </li>
                      {/* <li className="" data-bs-toggle="modal" data-bs-target="#Delete-file">
                        <img src="assets/img/Trash-icon.svg" />
                      </li>
                      <li>
                        <img src="assets/img/Edit.svg" />
                      </li> */}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={`d-flex justify-content-center ${(end !== assetsList.length) ? 'invisible' : ''}`}>
          <button onClick={onLoadMore} disabled={(end !== assetsList.length)} type="button" className="btn btn-outline-primary text-nowrap  sm-btn">
            LOAD MORE
          </button>
        </div>
      </>
    )
    

  };

  const onMediaTypeChange = (e) => {
    // if(!isPrivate && (!search || (search && !search.trim()))) {
    //   showToaster('Please enter search to show public items.', "warning");
    //   return;
    // }
    const value = e.target.value;
    setMediaType(value);
  }

  const onSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    clearTimeout(searchTimerRef.current)
    searchTimerRef.current = setTimeout(() => {
      if (value && value.trim()) {
        setIsSearch(is => !is);
      }
    }, 500)
  }

  const onSearchSubmit = (e) => {
    e.preventDefault();
    // if (search && search.trim()) {
    //   setIsSearch(is => !is);
    // }
  }

  const onSearchReset = (e) => {
    e.preventDefault();
    // if(!isPrivate) {
    //   showToaster('Search term is required to show public items.', "warning");
    //   return;
    // }
    setSearch('');
    setIsSearch(is => !is);
  }

  const onPrivate = (e) => {
    // if(!e.target.checked && (!search || (search && !search.trim()))) {
    //   showToaster('Please enter search to show public items.', "warning");
    //   return;
    // }
    setIsPrivate(e.target.checked);
  }

  const onAddNewFile = () => {
    setIsAddFile(true);
  }

  const closeAddFile = () => {
    setIsAddFile(false);
  }

  const uploadSuccess = ({ isPrivate: privateFlag, fileType }) => {
    if (privateFlag === isPrivate && fileType === mediaType) {
      setIsSearch(is => !is);
    }
  }

  const closeImagePreview = () => {
    setOpenImagePreview(false);
  }

  const closeVideoPreview = () => {
    setOpenVideoPreview(false);
  }

  return (
    <div>
      <div className={DisplayMode ? "theme dark" : "theme"}>
        {loading && <Loader />}
        <div className="main-wrapper">
          <Header />
          <div className="pb-wrapper">
            <div className="row pb-header mb-4">
              <div className="col-lg-12 col-xl-6 mb-2 mb-lg-0">
                <div className="d-flex align-items-center flex-wrap">
                  <button type="submit" onClick={() => history.goBack()} className="btn btn-outline-primary text-nowrap back-button me-3 mb-2 mb-lg-0" value="submit" data-bs-toggle="modal" data-bs-target="#render-error">
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.42652e-06 5.5C0.00211858 5.41315 0.0186696 5.32723 0.0490002 5.24563C0.0651946 5.21658 0.0839324 5.18898 0.105001 5.16313C0.123314 5.12504 0.144368 5.08828 0.168001 5.05313L4.368 0.240672C4.53056 0.0540017 4.78216 -0.0333139 5.02804 0.0116137C5.27392 0.0565413 5.47672 0.226887 5.56004 0.458484C5.64336 0.690081 5.59456 0.947744 5.432 1.13441L2.219 4.81251L13.3 4.81251C13.6866 4.81251 14 5.12031 14 5.5C14 5.87969 13.6866 6.18749 13.3 6.18749L2.219 6.18749L5.432 9.86559C5.59456 10.0523 5.64337 10.3099 5.56004 10.5415C5.47672 10.7731 5.27392 10.9435 5.02804 10.9884C4.78216 11.0333 4.53056 10.946 4.368 10.7593L0.168001 5.94687C0.144368 5.91172 0.123314 5.87496 0.105001 5.83687C0.0839324 5.81102 0.0651946 5.78342 0.0490003 5.75437C0.0186696 5.67277 0.00211859 5.58685 1.42652e-06 5.5Z" fill="" />
                    </svg>
                  </button>
                  <div className="pb-srch-customiser me-2 me-md-3">
                    <div className="custom-search srch-holder w-100 h-100">
                      <div className="srch-icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M21.9243 21.925L27.9994 28.0001" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                      </div>
                      <div className="srch-inpt-holder assets-search-close h-100">
                        <form onSubmit={onSearchSubmit} onReset={onSearchReset} className="h-100">
                          <input type="text" name="focus" value={search} onChange={onSearch} required className="pe-0 form-control srch-input h-100 " placeholder="Search by tag" />
                          <button className="search-close" type="reset">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0"
                              y="0"
                              version="1.1"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                            >
                              <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0zm71.115 365.904L256 294.789l-71.115 71.115-38.789-38.789L217.211 256l-71.115-71.115 38.789-38.789L256 217.211l71.115-71.115 38.789 38.789L294.789 256l71.115 71.115-38.789 38.789z"></path>
                            </svg>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* <div className="custom-check-2 me-2 me-md-3">
                    <input className="" type="checkbox" id="html-11" />
                    <label className="" for="html-11">Select All</label>
                  </div> */}
                  <div className="custom-check-2 me-2 me-md-3">
                    <input className="" type="checkbox" checked={isPrivate} onChange={onPrivate} id="html-111" />
                    <label className="" for="html-111">Private</label>
                  </div>
                  {/* <div className="delete-all" data-bs-toggle="modal" data-bs-target="#Delete-Selected-Files">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.875 4.375H3.125M8.125 8.125v5M11.875 8.125v5M15.625 4.375V16.25a.624.624 0 01-.625.625H5a.625.625 0 01-.625-.625V4.375"
                      ></path>
                      <path
                        stroke="0C2C4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.125 4.375v-1.25a1.25 1.25 0 00-1.25-1.25h-3.75a1.25 1.25 0 00-1.25 1.25v1.25"
                      ></path>
                    </svg>
                    <span>Delete</span>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-12 col-xl-6">
                <div className="d-flex flex-wrap justify-content-end">
                  <div className="d-flex align-items-center pb-filter-holder filter-by-status  me-3">
                    <div className="pb-filter-label pe-3">Filter by Type</div>
                    <div className="custom-select ">
                      <select onChange={onMediaTypeChange} value={mediaType} className="form-select form-field">
                        {/* <option> All</option> */}
                        <option value="IMAGE">Images</option>
                        <option value="ICON">Icons</option>
                        <option value="LOGO">Logos</option>
                        <option value="VIDEO">Videos</option>
                      </select>
                    </div>
                  </div>
                  {/* <button type="submit" className="btn btn-outline-primary text-nowrap add-user-btn me-2 me-md-3 sm-btn" value="submit">
                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.125 10H16.875" stroke="#C0C2C4" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10 3.125V16.875" stroke="#C0C2C4" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    CREATE NEW FOLDER
                  </button> */}
                  <button type="button" onClick={onAddNewFile} className="btn btn-outline-primary text-nowrap add-user-btn sm-btn" value="submit" data-bs-toggle="modal" data-bs-target="#Add-new-file">
                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.125 10H16.875" stroke="#C0C2C4" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10 3.125V16.875" stroke="#C0C2C4" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    ADD NEW FILE
                  </button>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-11">
                {
                  assetsList.length > 0 ? (
                    ListAssets(assetsList)
                  ) : (
                    <div className="no-data">
                      <p>No data available</p>
                    </div>
                  )
                }
              </div>
            </div>



          </div>
          <UploadAsset mediaType={mediaType} isAddFile={isAddFile} closeAddFile={closeAddFile} uploadSuccess={uploadSuccess} />
          <ImagePriview openImagePreview={openImagePreview} closePreview={closeImagePreview} selectedImage={selectedAsset?.mediaurl} />
          <PreviewModal
            closePreview={closeVideoPreview}
            openPreviewModals={openVideoPreview}
            previewUrl={selectedAsset?.mediaurl}
            thumbnailUrl={selectedAsset?.thumbnailurl}
          />
          
        </div>
      </div>
    </div>
  );
}

export default VisualAssets;
