export const INITIALIZEPROJECT ='initializeproject'
export const PROJECTFROMLOCALSTORAGE = "projectfromlocalstorage"
export const ADDMODULE = 'addmodule';
export const REMOVEMODULE = 'removemodule';
export const SELECTMODULE = 'selectmodule'
export const RENAMEMODULE = 'renamemodule';
export const ADDSLIDE = 'addslide';
export const REMOVESLIDE ='removeslide';
export const SELECTSLIDE = 'selectslide'
export const RENAMESLIDE = 'renameslide';
export const ADDSCREEN = 'addscreen'
export const REMOVESCREEN = 'removescreen'
export const SELECTSCREEN = 'selectscreen'
export const RENAMESCREEN = 'renamescreen'
export const EDITAUDIOSCRIPT = 'editaudioscript'
export const SELECTOST = 'selectost'
export const EDITOST="editost"
export const ADDFEATURE="addfeature"
export const EDITFEATURE = "editfeature"
export const DELETEFEATURE="deletefeature"
export const SELECTIMAGE = 'selectimage'
export const SELECTILLUSTRATION = 'selectillustration'
export const SELECTVIDEO = 'selectvideo'
export const EDITASSET = 'editasset'
export const EDITSCREENDURATION='editscreenduration'
export const UPDATETEMPLATEURL='updatetemplateurl'
export const UPDATETEMPLATEURLPREVIEW="updatetemplateurlpreview"
export const SETOSTFONT='setostfont'
export const CHANGEPROJECTNAME = 'changeprojectname'
export const EDITPROJECT = 'editproject'
export const EDITMODE = 'editmode'
export const PREVIOUSACTIVE = 'previousactive'
export const EMPTYPROJECT = "emptyproject"
export const SETOSTFONTFORNEWSCREENS = "setostfontfornewscreens"
export const UPDATEOSTFONT = "updateostfont"
//project Style
export const TOGGLERIGHTSCREEN='togglerightscreen'
export const TOGGLELEFTSCREEN='toggleleftscreen'
export const DISPLAYMODE = 'displaymode'
// Preview reducer
export const INITIALIZEPREVIEW = 'initializepreview'
export const SELECTACTIVESCREEN = "selectactivescreen"
export const SELECTACTIVESLIDE = "selectactiveslide"
export const SELECTACTIVEMODULE = "selectactivemodule"
export const PREVIEW_UPDATEFEATURE = "updatefeature"
export const PREVIEW_SETOSTFONT = "previewsetostfont"
export const UPDATE_AUDIOSCRIPT_UPLOAD = "updateaudioscriptupload"
export const PREVIOUS_ACTIVE_TO_PREVIEW = "previousactivetopreview"
export const ASSIGN_THUMBNAIL = "assignthumbnail"
export const CHANGEPROJECTNAMEINPREVIEW = 'changeprojectnameinpreview'
export const UPDATETHUMBNAIL = 'updatethumbnail'
export const EMPTYPREVIEW = 'emptypreview'
export const UPDATEPREVIEW = 'updatepreview'
export const UPDATESCREENPREVIEW = 'updatescreenpreview'
export const DELETESCREENPREVIEW = 'deletescreenpreview'