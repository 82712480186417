import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import {
  BgColor,
  BULLETFONT,
  FONT_SIZE_H,
  FONT_SIZE_P,
  FONT_URL,
  HEADERFONT,
  INIT_OST_FONT,
  PARAGRAPHFONT,
  TextColor,
} from "../../../constants";
import {
  getUserItem,
  RandomGenerator,
  showToaster,
} from "../../../services/helper";
import {
  TEMPLATEHEADINGSIZE,
  TEMPLATEILLUSTRATIONS,
  TEMPLATEIMAGES,
  TEMPLATEVIDEOS,
} from "./TemplateAssets";
import { CreateProjectTemplateModel } from "../../../services/DataHelper";
import {
  getAllFonts,
  projectScreenPreview,
  uploadMediaFile,
} from "../../../services/api.service";
import Videoplayer from "../../components/Videoplayer/Videoplayer";
import Select from "react-select";
import {
  findTemplateById,
  getAllTemplateAsset,
  getTemplateAssetIllustration,
  getTemplateAssets,
  getTemplateAssetVideos,
  saveAsset,
  saveTemplate,
  UpdateTemplatebyId,
  createTemplateProject,
  getOrganisation,
  getUsersGlobalRole,
} from "../../../services/authService";

import CustomModal from "../../../services/CustomModal";
import Header from "../../components/header/header";
import PageLoader from "../../../services/pageLoader";
import { socket } from "../../../services/socket.service";
import { customAlphabet } from "nanoid/async";
import { Axioslib } from "../../../lib/axioslib";
import { useHistory, useLocation } from "react-router";
import { useStateWithCallbackLazy } from "use-state-with-callback";
function TemplateUpload() {
  const history = useHistory();
  const nanoid = customAlphabet(
    "abcdefghijklmnopqrstuvzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
    24
  );
  const [selectedFile, setselectedFile] = useState(undefined);
  const [selectedTemplateType, setselectedTemplateType] =
    useState("motion_graphics");
  const [uploadResponse, setuploadResponse] = useState([]);
  const [loading, setloading] = useState(false);
  const [modalLoading, setmodalLoading] = useState(true);
  const [showTemplateModel, setshowTemplateModel] = useState(false);
  const [templateModel, settemplateModel] = useState(undefined);
  const [showPreviewVideo, setshowPreviewVideo] = useState(false);
  const [mediaUrlArr, setmediaUrlArr] = useState(undefined);
  const [no_of_ost_h, setno_of_ost_h] = useState(0);
  const [no_of_ost_p, setno_of_ost_p] = useState(0);
  const [no_of_ost_b, setno_of_ost_b] = useState(0);
  const [no_of_images, setno_of_images] = useState(0);
  const [no_of_videos, setno_of_videos] = useState(0);
  const [no_of_illustrations, setno_of_illustrations] = useState(0);
  const [ost_length, setost_length] = useState(undefined);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [onlyValidate, setonlyValidate] = useState(false);
  const [ost_error, setost_error] = useState(false);
  const [progressCount, setprogressCount] = useState(0);
  const [generatePreviewStatus, setgeneratePreviewStatus] = useState(false);
  const [audioScript, setaudioScript] = useState(undefined);
  const [duration, setduration] = useState(7);
  const [fontArray, setfontArray] = useState(undefined);
  const [fonts, setfonts] = useState(undefined);
  const [FontFamily, setFontFamily] = useState(undefined);
  const [FontStyle, setFontStyle] = useState(undefined);
  const [regular, setRegular] = useState();
  const [textColor, settextColor] = useState("#ffffff");
  const [bgColor, setbgColor] = useState("#000000");
  const [FontSizeH, setFontSizeH] = useState(30);
  const [FontSizeP, setFontSizeP] = useState(24);
  const [templateAsset, settemplateAsset] = useState(undefined);
  const [templateAssetVideos, settemplateAssetVideos] = useState(undefined);
  const [templateasseetIllustration, settemplateasseetIllustration] =
    useState(undefined);
  const [templateResponse, settemplateResponse] = useState(undefined);
  const [tAlreadyExist, settAlreadyExist] = useState(false);
  const [tempValid, setTempValid] = useState(false);
  const [tempError, setTempError] = useState(undefined);
  const [tdata, settdata] = useState(undefined);
  const [showAssetUploadModel, setshowAssetUploadModel] = useState(false);
  const [assetUploadType, setassetUploadType] = useState(undefined);
  const [uploadedAsset, setuploadedAsset] = useState(undefined);
  const [retry, setretry] = useState(false);
  const [publicPrivateOption, setPublicPrivateOption] = useState(1);
  const [openUpdateModal, setopenUpdateModal] = useState(false);
  const [listStatus, setListStatus] = useState({
    value: "All",
    label: "All",
    orgId: 0,
  });
  const [statusOption, setStatusOption] = useStateWithCallbackLazy();
  const [listFilterStatus, setListFilterStatus] = useState([]);
  let Email = getUserItem("email");
  let OrganiId = getUserItem("orgId");
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");
  let TemplateFeature = getUserItem("templateFeature");
  useEffect(() => {
    const userId = getUserItem("id");
    socket.on("TemplatePreview", (data) => {
      // console.log('TemplatePreview', data);
      const { userId: dataUserId, tId, mediaurl } = data;
      // console.log(templateResponse)
      if (
        templateResponse &&
        tId.toString() === templateResponse.tid.toString() &&
        mediaurl &&
        mediaurl["ThumbnailUrl"] &&
        userId === dataUserId
      ) {
        setprogressCount(100);
        setretry(false);
        setgeneratePreviewStatus(false);
        setshowPreviewVideo(true);
        setmediaUrlArr(mediaurl);
      }
    });

    return () => {
      socket.off("TemplatePreview");
    };
  }, [templateResponse]);
  const getuserGlobelRolesAdmin = () => {
    let RoleUserId = AdminRole == 0 ? userId : OrganiId;
    getUsersGlobalRole(RoleUserId).then((response) => {
      console.log(response, "response");
      try {
        setloading(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null &&
          response.data.result !== undefined &&
          response.data.result !== "" &&
          response.data.result !== null &&
          response.data.result[0] !== undefined &&
          response.data.result[0] !== "" &&
          response.data.result[0] !== null
        ) {
          console.log(response, "response");
          // setCategoryList(ddd)
          setloading(false);

          if (response.data.result[0].templateUpload !== undefined) {
            if (response.data.result[0].templateUpload == false) {
              history.push("/project-list");
            }
          }
        } else {
          setloading(false);
          // showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        console.log(error);

        setloading(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  useEffect(() => {
    console.log(OrganiId, "OrganiIds");
    getuserGlobelRolesAdmin();
    getOrganisationList();
  }, []);

  // On file select (from the pop up)
  const onFileChange = (event) => {
    console.log(event, "event");
    // Update the state]
    let imageFile = document.getElementById("assestUpload");

    setselectedFile(imageFile.files[0]);
    setuploadResponse("");
  };

  const changeTemplateType = (e) => {
    // console.log(e.target.value);
    setselectedTemplateType(e.target.value);
  };

  const handleChangeStatus = (e) => {
    // alert()
    setListStatus(e);
    console.log(e, "listtt");
  };

  const generateStatus = () => {
    if (listFilterStatus != undefined) {
      let statusOption = [];
      listFilterStatus.map((filter) => {
        console.log(filter);
        statusOption.push({
          value: filter.orgName !== undefined ? filter.orgName : filter.email,
          label: filter.orgName !== undefined ? filter.orgName : filter.email,
          orgId: filter._id,
        });
        return true;
      });
      setStatusOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generateStatus();
    setListStatus({
      value: "All",
      label: "All",
      orgId: 0,
    });
  }, [listFilterStatus]);
  useEffect(() => {}, [listStatus]);

  const getOrganisationList = () => {
    let filter = {
      params: 0,
      keyword: "",
      sortCreateAt: "",
      sortUpdatedAt: "",
    };
    getOrganisation(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          // this.fontArray = response.data
          console.log(response.data);
          let projectData = {
            orgName: "All",
            orgId: 0,
          };
          let orglist = [projectData, ...response.data.result.data];
          console.log(orglist);
          setListFilterStatus(orglist);
          //generateFonts();
        }
      } catch (error) {}
    });
  };

  const getFonts = () => {
    getAllFonts().then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          // this.fontArray = response.data
          //console.log(response.data);
          setfontArray(response.data);
          //generateFonts();
        }
      } catch (error) {}
    });
  };
  useEffect(async () => {
    setprogressCount(0);
    let _font = INIT_OST_FONT;
    setFontFamily({
      value: _font.split("/")[4],
      label: _font.split("/")[4],
    });
    setFontStyle({
      value: _font.split("/")[5],
      label: _font.split("/")[5],
    });
    let tempAssets = await getAllTemplateAsset();
    if (tempAssets.data.result.data[0].assets != undefined) {
      settemplateAsset(tempAssets.data.result.data[0].assets.images);
      settemplateasseetIllustration(
        tempAssets.data.result.data[0].assets.illustrations
      );
      settemplateAssetVideos(tempAssets.data.result.data[0].assets.videos);
    }
    // settemplateAsset(tempAssets.data);
    // settemplateAssetVideos(tempassetsvideos.data);
    // settemplateasseetIllustration(tempassetillustration.data);
  }, []);

  useEffect(() => {
    if (templateModel != undefined) {
      console.log(FontFamily, FontStyle);
      ChangeOstValues();
    }
  }, [FontFamily, FontStyle, FontSizeH, FontSizeP, textColor, bgColor]);

  const ChangeOstValues = () => {
    if (templateModel != undefined) {
      let data = JSON.parse(templateModel);
      console.log(FontFamily, FontStyle);
      Object.keys(data).map((item) => {
        if (item.startsWith("OS")) {
          console.log(data[item]);
          data[item].Contentparams.FontFamily =
            FONT_URL + FontFamily.value + "/" + FontStyle.value;
          // data[item].Contentparams
          data[item].Contentparams.FontColour = textColor;
          data[item].Contentparams.FontSizeH = FontSizeH;
          data[item].Contentparams.FontSizeP = FontSizeP;
        }
        if (item == "CS01") {
          data[item].Contentparams.contentdescription = bgColor;
        }
      });
      console.log(data);
      settemplateModel(JSON.stringify(data));
    }
  };

  useEffect(() => {
    generateFonts();
  }, [fontArray]);

  const generateFonts = () => {
    if (fontArray != undefined) {
      let sampleArray = fontArray.map((font) => font.split("/")[4]);
      // this.updateState();
      let uniqueArray = sampleArray.filter(
        (item, i, ar) => ar.indexOf(item) === i
      );
      let fontOption = [];
      uniqueArray.map((font) => {
        fontOption.push({ value: font, label: font });
        return true;
      });
      // setfonts(fontOption)
      // handleChange(fontOption[0])
      //console.log(uniqueArray);
      setfonts(fontOption);
    }
  };

  const handleChange = (e) => {
    // alert()
    let regularArray = [];
    let regularOption = [];
    fontArray.map((font) =>
      font.split("/")[4] === e.value
        ? regularArray.push(font.split("/")[5])
        : ""
    );
    regularArray.map((font) => {
      regularOption.push({ value: font, label: font });
      return true;
    });
    setRegular(regularOption);
    setFontFamily(e);
    setFontStyle(regularOption[0]);
  };
  const handleChangeRegular = (e) => {
    setFontStyle(e);
  };

  const CreateTemplateData = (configData) => {
    // console.log(configData);
    return new Promise((resolve, reject) => {
      let data = {
        ScreenName: "Screen 01",
        BG01: {
          Contenttype: "Background",
          Contentparams: {
            sequenceid: "1",
            contentdescription: "#2066a2",
            contenttag: "Null",
          },
        },
        AS01: {
          Contenttype: "AudioScript",
          Contentparams: {
            sequenceid: "2",
            contentdescription: "",
            contenttag: "Null",
          },
        },
        CS01: {
          Contenttype: "ColourScheme",
          Contentparams: {
            sequenceid: "3",
            contentdescription: BgColor,
            contenttag: "Null",
          },
        },
        CS02: {
          Contenttype: "ColourScheme",
          Contentparams: {
            sequenceid: "4",
            contentdescription: "#808080",
            contenttag: "Null",
          },
        },
        SD01: {
          Contenttype: "ScreenDuration",
          Contentparams: {
            contenttag: "Null",
            sequenceid: "5",
            contentdescription: duration,
          },
        },
      };
      var count = 0;
      let c = 5;
      if (configData.no_of_ost_h > 0) {
        // console.log(Number(count) + 1, "heading");

        for (let i = 1; i <= configData.no_of_ost_h; i++) {
          let totalNumber = Number(count) + 1;
          if (count == 0) {
            if (i > 9) {
              count = i;
            } else {
              count = "0" + i;
            }
          } else {
            if (totalNumber < 9) {
              count = "0" + totalNumber;
            }
          }
          c++;
          data[`OS${count}`] = {
            Contenttype: "OST",
            Contentparams: {
              sequenceid: c,
              contentdescription: HEADERFONT,
              contenttag: "Null",
              FontFamily: INIT_OST_FONT,
              FontSizeH: TEMPLATEHEADINGSIZE,
              FontSizeP: FONT_SIZE_P,
              FontColour: TextColor,
              // fontTag: HEADERFONT,
            },
          };
        }
      }
      if (configData.no_of_ost_p > 0) {
        for (let i = 1; i <= configData.no_of_ost_p; i++) {
          let totalNumber = Number(count) + 1;
          // var count = 0;
          // if (i > 9) {
          //   count = i;
          // } else {
          //   count = "0" + i;
          // }
          if (count == 0) {
            if (i > 9) {
              count = i;
            } else {
              count = "0" + i;
            }
          } else {
            if (totalNumber < 9) {
              count = "0" + totalNumber;
            }
          }
          c++;
          data[`OS${count}`] = {
            Contenttype: "OST",
            Contentparams: {
              sequenceid: c,
              contentdescription: PARAGRAPHFONT,
              contenttag: "Null",
              FontFamily: INIT_OST_FONT,
              FontSizeH: TEMPLATEHEADINGSIZE,
              FontSizeP: FONT_SIZE_P,
              FontColour: TextColor,
              // fontTag: PARAGRAPHFONT,
            },
          };
        }
      }
      if (configData.no_of_ost_b > 0) {
        // console.log(Number(count) + 1, "bullet");

        for (let i = 1; i <= configData.no_of_ost_b; i++) {
          let totalNumber = Number(count) + 1;
          console.log(i, totalNumber);
          if (count == 0) {
            if (i > 9) {
              count = i;
            } else {
              count = "0" + i;
            }
          } else {
            if (totalNumber < 9) {
              count = "0" + totalNumber;
            }
          }
          // var count = 0;
          c++;
          data[`OS${count}`] = {
            Contenttype: "OST",
            Contentparams: {
              sequenceid: c,
              contentdescription: BULLETFONT,
              contenttag: "Null",
              FontFamily: INIT_OST_FONT,
              FontSizeH: TEMPLATEHEADINGSIZE,
              FontSizeP: FONT_SIZE_P,
              FontColour: TextColor,
              // fontTag: BULLETFONT,
            },
          };
        }
      }
      if (configData.no_of_images > 0) {
        for (let i = 1; i <= configData.no_of_images; i++) {
          var count = 0;
          if (i > 9) {
            count = i;
          } else {
            count = "0" + i;
          }
          c++;
          data[`IM${count}`] = {
            Contenttype: "Image",
            Contentparams: {
              sequenceid: c,
              contentdescription: templateAsset[0],
              searchstring: "",
              contenttag: "Null",
            },
          };
        }
      }
      if (configData.no_of_illustrations > 0) {
        for (let i = 1; i <= configData.no_of_illustrations; i++) {
          var count = 0;
          if (i > 9) {
            count = i;
          } else {
            count = "0" + i;
          }
          c++;
          data[`IL${count}`] = {
            Contenttype: "Illustration",
            Contentparams: {
              sequenceid: c,
              contentdescription: templateasseetIllustration[0],
              searchstring: "",
              contenttag: "Null",
            },
          };
        }
      }
      if (configData.no_of_videos > 0) {
        for (let i = 1; i <= configData.no_of_videos; i++) {
          var count = 0;
          if (i > 9) {
            count = i;
          } else {
            count = "0" + i;
          }
          c++;
          data[`VD${count}`] = {
            Contenttype: "Video",
            Contentparams: {
              contenttag: "Null",
              sequenceid: c,
              contentdescription: templateAssetVideos[0],
              searchstring: "",
            },
          };
        }
      }
      data["TM01"] = {
        Contenttype: "TemplateID",
        Contentparams: {
          contenttag: "Null",
          sequenceid: c + 1,
          TemplateType: selectedTemplateType,
          contentdescription: configData.mediaurl,
          useTemplateStatus: false,
          sourcescreen: "",
        },
      };
      resolve(data);
    });
  };

  const changeOstContent = (value, ost, tag) => {
    let data = JSON.parse(templateModel);
    data[ost].Contentparams.contentdescription = value + tag;
    settemplateModel(JSON.stringify(data));
    // console.log(data);
  };

  const changeVisualAsset = (mediaUrl, key) => {
    let data = JSON.parse(templateModel);
    data[key].Contentparams.contentdescription = mediaUrl;
    settemplateModel(JSON.stringify(data));
  };

  const changeVisualAssetVideo = (mediaUrl, VideoUrl, key) => {
    let data = JSON.parse(templateModel);
    data[key].Contentparams.contentdescription = mediaUrl;
    settemplateModel(JSON.stringify(data));
  };

  useEffect(() => {
    // console.log("changed");
  }, [templateModel]);

  const runProgressbar = () => {
    let i = 0;
    let a = setInterval(() => {
      // if (progressCount <= 99) {
      i++;
      // console.log(i);
      if (i <= 99) {
        setprogressCount(i);
      } else {
        clearInterval(a);
      }
      // }
    }, 500);
  };
  const previewFailed = () => {
    setgeneratePreviewStatus(false);
    setshowTemplateModel(false);
    setmediaUrlArr(undefined);
    setmodalLoading(false);
    setshowPreviewVideo(false);
    setuploadResponse("");
  };
  const PreviewGenerate = async () => {
    setretry(false);
    setprogressCount(0);
    setgeneratePreviewStatus(true);
    runProgressbar();
    setmediaUrlArr(undefined);
    const tempProjectId = await nanoid();
    let templateDataModel = await CreateProjectTemplateModel(
      tempProjectId,
      RandomGenerator(10),
      JSON.parse(templateModel)
    );
    // console.log(templateDataModel);
    templateDataModel = { ...templateDataModel, tId: templateResponse.tid };
    createTemplateProject(templateDataModel).catch(console.log);
    let response = await projectScreenPreview(templateDataModel);
    setTimeout(() => {
      setopenUpdateModal(true);
    }, 6000000);

    if (response != undefined && response != "" && response != null) {
      setprogressCount(100);
      if (
        response.data != undefined &&
        response.data != "" &&
        response.data != null
      ) {
        if (
          response.data.mediaurl != undefined &&
          response.data.mediaurl != "" &&
          response.data.mediaurl != null
        ) {
          setretry(false);
          console.log(response.data);
          setgeneratePreviewStatus(false);
          setshowPreviewVideo(true);
          setmediaUrlArr(response.data.mediaurl);
          let mediaUrls = {
            previewUrl: response.data.mediaurl.PreviewUrl,
            thumbnailUrl: response.data.mediaurl.ThumbnailUrl,
          };
          let savePreview = await UpdateTemplatebyId(
            templateResponse.tid,
            mediaUrls
          );
        }
      } else {
        showToaster("Something went wrong", "warning");
      }
    } else {
      setprogressCount(100);
      showToaster("Something went wrong", "warning");
      setretry(true);
    }
    // console.log(response);
  };

  const generateOstFields = () => {
    if (
      templateModel !== undefined &&
      templateModel != null &&
      templateModel != ""
    ) {
      let tabHead = [];
      let H = [];
      let P = [];
      let B = [];
      let tabTextarea = [];
      let OSTArray = Object.fromEntries(
        Object.entries(JSON.parse(templateModel)).filter(([key]) =>
          key.includes("OS")
        )
      );
      Object.entries(OSTArray).map((i, item) => {
        const ostName = i[0].replace("OS0", "");
        var array = ostName.split("OS");
        let tag = i[1].Contentparams.contentdescription.substr(
          i[1].Contentparams.contentdescription.length - 3
        );
        tag == HEADERFONT
          ? H.push(i[0])
          : tag == PARAGRAPHFONT
          ? P.push(i[0])
          : tag == BULLETFONT && B.push(i[0]);
        tabHead.push(
          <div
            className={`panel-label cm-gen-spec cm-full-lst-container ${
              array == 1 && "active"
            }`}
            title={`OST ${array}`}
            id={`nav-home-tab-${array}`}
            data-bs-toggle="tab"
            data-bs-target={`#nav-ost${array}`}
          >
            <p>
              {tag == HEADERFONT ? (
                <>
                  {`Heading`}(
                  <span style={{ fontSize: "12px" }}>
                    {ost_length.heading_length[H.length - 1]} Characters
                  </span>
                  )
                </>
              ) : tag == PARAGRAPHFONT ? (
                <>
                  {`Paragraph`}(
                  <span style={{ fontSize: "12px" }}>
                    {ost_length.paragraph_length[P.length - 1]}) Characters
                  </span>
                </>
              ) : tag == BULLETFONT ? (
                <>
                  {`Bullet`}(
                  <span style={{ fontSize: "12px" }}>
                    {ost_length.bullet_length[B.length - 1]}) Characters
                  </span>
                </>
              ) : (
                "Tab"
              )}
            </p>
          </div>
        );
        // console.log(ost_length.heading_length);
        tabTextarea.push(
          <div
            className={`tab-pane  show ${array == 1 && "active"}`}
            id={`nav-ost${array}`}
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <textarea
              placeholder="Enter OST"
              className="panel-txtarea"
              onChange={(e) => changeOstContent(e.target.value, i[0], tag)}
              required
              maxLength={
                tag == HEADERFONT
                  ? ost_length.heading_length[H.length - 1]
                  : tag == PARAGRAPHFONT
                  ? ost_length.paragraph_length[P.length - 1]
                  : tag == BULLETFONT && ost_length.bullet_length[B.length - 1]
              }
              onInvalid={() => {
                let element = document.getElementById(`nav-home-tab-${array}`);
                element.classList.add("red-text");
                setost_error(true);
              }}
              onInput={() => {
                let element = document.getElementById(`nav-home-tab-${array}`);
                element.classList.remove("red-text");
              }}
            ></textarea>
            {/* <small>
              Maximum{" "}
              {tag == HEADERFONT
                ? ost_length.heading_length[H.length - 1]
                : tag == PARAGRAPHFONT
                ? ost_length.paragraph_length[P.length - 1]
                : tag == BULLETFONT &&
                  ost_length.bullet_length[B.length - 1]}{" "}
              characters allowed
            </small> */}
          </div>
        );
      });
      return (
        <>
          {ost_error && (
            <div>
              <p className="error-msg-box ">Please fill Ost fields</p>
            </div>
          )}

          <div
            className="tab-pane show active"
            id="cm-heading"
            role="tabpanel"
            aria-labelledby="heading-tab"
          >
            <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
              <div
                className="d-flex cm-full-lst-wrapper nav nav-tabs border-0 flex-nowrap"
                id="nav-tab"
                role="tablist"
              >
                {tabHead.map((tabs) => {
                  return tabs;
                })}
              </div>
            </div>
            <div
              className="panel-txtarea-blk ost-txt tab-content"
              id="nav-tabContent"
            >
              {tabTextarea.map((tabField) => {
                return tabField;
              })}
            </div>
          </div>
        </>
      );
    } else {
    }
  };

  const changeAudioscriptContent = (value, key) => {
    let data = JSON.parse(templateModel);
    data[key].Contentparams.contentdescription = value;
    settemplateModel(JSON.stringify(data));
    console.log(data);
  };

  const changeScreenDurationContent = (value, key) => {
    let data = JSON.parse(templateModel);
    data[key].Contentparams.contentdescription = value;
    settemplateModel(JSON.stringify(data));
  };

  const generateAudioScriptField = () => {
    return (
      <div className="panel-txtarea-blk ost-txt tab-content">
        <textarea
          placeholder="Enter OST"
          className="panel-txtarea"
          onChange={(e) => changeAudioscriptContent(e.target.value, "AS01")}
          required
          onInvalid={() => {}}
          onInput={() => {}}
        ></textarea>
      </div>
    );
  };

  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      height: "26px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      //     whiteSpace: 'nowrap',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
      fontSize: "12px",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
  };

  const generateStyleField = () => {
    return (
      <div>
        <div className="field-block">
          <div className=" mb-4 d-flex">
            {/* <select className="form-select form-field"> */}
            <Select
              className="w-50 "
              styles={customStyles}
              value={FontFamily}
              onChange={(e) => handleChange(e)}
              options={fonts}
            />
            <div className=" Regular ms-3 w-50">
              {/* <select className="form-select form-field">
                                                <option> Regular</option>
                                            </select> */}
              <Select
                className=""
                styles={customStyles}
                value={FontStyle}
                onChange={handleChangeRegular}
                options={regular}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap mb-3">
          <div className="color-picker me-4 nw-color-pick">
            <span className="color-palette">
              <input
                type="color"
                className="custom-color-picker"
                value={textColor}
                onChange={(e) => settextColor(e.target.value)}
              />
              <i className="eye-drop">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.49944 1.66429C9.49704 1.43857 9.44904 1.21566 9.35832 1.00895C9.26761 0.802247 9.13605 0.616009 8.97155 0.461424C8.31445 -0.160631 7.25036 -0.137216 6.59956 0.513609L5.57073 1.54246C5.35677 1.34753 5.07599 1.24245 4.78662 1.24901C4.49726 1.25558 4.22153 1.37328 4.01662 1.5777L3.6871 1.90723C3.54666 2.04798 3.46779 2.23871 3.46779 2.43755C3.46779 2.63639 3.54666 2.82711 3.6871 2.96787L3.78362 3.06438L1.42376 5.42422C1.20805 5.6408 1.04833 5.90665 0.958401 6.1988C0.868474 6.49095 0.851049 6.8006 0.90763 7.10099L0.449066 8.1514C0.396728 8.26998 0.381601 8.40165 0.405683 8.52901C0.429766 8.65637 0.491925 8.77342 0.583946 8.86471C0.653202 8.93448 0.735565 8.98987 0.826301 9.02771C0.917036 9.06554 1.01436 9.08507 1.11266 9.08517C1.21651 9.08504 1.31922 9.06344 1.41433 9.02174L2.39866 8.59202C2.69905 8.64861 3.00871 8.63118 3.30085 8.54125C3.593 8.45132 3.85885 8.29159 4.07542 8.07587L6.43526 5.71603L6.53178 5.81254C6.67254 5.95299 6.86326 6.03186 7.0621 6.03186C7.26094 6.03186 7.45166 5.95299 7.59242 5.81254L7.92195 5.48302C8.12638 5.27813 8.24409 5.00239 8.25065 4.71303C8.25722 4.42366 8.15213 4.14287 7.9572 3.92892L9.00534 2.88077C9.16542 2.72176 9.29171 2.53204 9.37662 2.32299C9.46152 2.11394 9.5033 1.88989 9.49944 1.66429ZM3.5451 7.54555C3.40518 7.6849 3.23117 7.78513 3.04042 7.83624C2.84967 7.88735 2.64886 7.88756 2.45801 7.83684C2.37601 7.81497 2.28903 7.82154 2.21125 7.85548L1.20479 8.29486L1.64417 7.2884C1.67812 7.21062 1.68469 7.12363 1.66281 7.04164C1.6121 6.85079 1.61232 6.64998 1.66343 6.45923C1.71454 6.26849 1.81477 6.09447 1.9541 5.95454L4.31394 3.5947L5.90494 5.1857L3.5451 7.54555Z" />
                </svg>
              </i>
            </span>
            <p className="">Text</p>
          </div>
          <div className="color-picker me-4 nw-color-pick">
            <span className="color-palette">
              <input
                type="color"
                className="custom-color-picker"
                value={bgColor}
                onChange={(e) => {
                  setbgColor(e.target.value);
                }}
              />
              {/* <input type="color" className="custom-color-picker"/> */}
              <i className="eye-drop">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.49944 1.66429C9.49704 1.43857 9.44904 1.21566 9.35832 1.00895C9.26761 0.802247 9.13605 0.616009 8.97155 0.461424C8.31445 -0.160631 7.25036 -0.137216 6.59956 0.513609L5.57073 1.54246C5.35677 1.34753 5.07599 1.24245 4.78662 1.24901C4.49726 1.25558 4.22153 1.37328 4.01662 1.5777L3.6871 1.90723C3.54666 2.04798 3.46779 2.23871 3.46779 2.43755C3.46779 2.63639 3.54666 2.82711 3.6871 2.96787L3.78362 3.06438L1.42376 5.42422C1.20805 5.6408 1.04833 5.90665 0.958401 6.1988C0.868474 6.49095 0.851049 6.8006 0.90763 7.10099L0.449066 8.1514C0.396728 8.26998 0.381601 8.40165 0.405683 8.52901C0.429766 8.65637 0.491925 8.77342 0.583946 8.86471C0.653202 8.93448 0.735565 8.98987 0.826301 9.02771C0.917036 9.06554 1.01436 9.08507 1.11266 9.08517C1.21651 9.08504 1.31922 9.06344 1.41433 9.02174L2.39866 8.59202C2.69905 8.64861 3.00871 8.63118 3.30085 8.54125C3.593 8.45132 3.85885 8.29159 4.07542 8.07587L6.43526 5.71603L6.53178 5.81254C6.67254 5.95299 6.86326 6.03186 7.0621 6.03186C7.26094 6.03186 7.45166 5.95299 7.59242 5.81254L7.92195 5.48302C8.12638 5.27813 8.24409 5.00239 8.25065 4.71303C8.25722 4.42366 8.15213 4.14287 7.9572 3.92892L9.00534 2.88077C9.16542 2.72176 9.29171 2.53204 9.37662 2.32299C9.46152 2.11394 9.5033 1.88989 9.49944 1.66429ZM3.5451 7.54555C3.40518 7.6849 3.23117 7.78513 3.04042 7.83624C2.84967 7.88735 2.64886 7.88756 2.45801 7.83684C2.37601 7.81497 2.28903 7.82154 2.21125 7.85548L1.20479 8.29486L1.64417 7.2884C1.67812 7.21062 1.68469 7.12363 1.66281 7.04164C1.6121 6.85079 1.61232 6.64998 1.66343 6.45923C1.71454 6.26849 1.81477 6.09447 1.9541 5.95454L4.31394 3.5947L5.90494 5.1857L3.5451 7.54555Z" />
                </svg>
              </i>
            </span>
            <p className="">Background</p>
          </div>
        </div>
        <div className="field-block">
          <div className="d-flex">
            <div className="mb-2 me-2 w-50">
              <label className="custom-label">Heading Size</label>
              <input
                className="form-control inp-txt form-field"
                placeholder="Enter FontH"
                type="number"
                value={FontSizeH}
                onChange={(e) => setFontSizeH(e.target.value)}
              />
            </div>
            <div className="w-50">
              <label className="custom-label">Paragraph & Bullet Size</label>
              <input
                className="form-control inp-txt form-field"
                placeholder="Enter FontP"
                type="number"
                value={FontSizeP}
                onChange={(e) => setFontSizeP(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const generateDurationField = () => {
    return (
      <input
        type="number"
        onChange={(e) => {
          setduration(e.target.value);
          changeScreenDurationContent(e.target.value, "SD01");
        }}
        value={duration}
      />
    );
  };

  const generateVisualAssets = () => {
    if (
      templateModel !== undefined &&
      templateModel != null &&
      templateModel != ""
    ) {
      let tabHead = [];
      let tabTextarea = [];
      let OSTArray = Object.fromEntries(
        Object.entries(JSON.parse(templateModel)).filter(
          ([key]) =>
            key.includes("IM") || key.includes("IL") || key.includes("VD")
        )
      );
      var templateModelObj = JSON.parse(templateModel);
      Object.entries(OSTArray).map((i, item) => {
        if (i[1].Contenttype == "Image") {
          const ostName = i[0].replace("IM0", "");
          var array = ostName.split("IM");
          tabHead.push(
            <div
              className={`panel-label cm-gen-spec cm-full-lst-container ${
                array == 1 && "active"
              }`}
              title={`Image ${array}`}
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target={`#nav-im${array}`}
            >
              <p>{`Image ${array}`}</p>
            </div>
          );
          tabTextarea.push(
            <div
              className={`tab-pane  show ${array == 1 && "active"}`}
              id={`nav-im${array}`}
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="img-list">
                <div className="grid-box">
                  {templateAsset != undefined ? (
                    templateAsset.map((image, index) => {
                      return (
                        <div
                          onClick={() => changeVisualAsset(image, i[0])}
                          className={`grid-list ${
                            templateModelObj[i[0]].Contentparams
                              .contentdescription == image
                              ? "active"
                              : ""
                          }`}
                        >
                          <div className="tick-active">
                            <img src="assets/img/asset-tick.svg" />
                          </div>
                          <img src={image} />
                        </div>
                      );
                    })
                  ) : (
                    <p>Loading ...</p>
                  )}
                </div>
              </div>
            </div>
          );
        }
      });

      return (
        <>
          <div
            className="tab-pane show active"
            id="cm-heading"
            role="tabpanel"
            aria-labelledby="heading-tab"
          >
            <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
              <div
                className="d-flex cm-full-lst-wrapper nav nav-tabs border-0 flex-nowrap"
                id="nav-tab"
                role="tablist"
              >
                {tabHead.map((tabs) => {
                  return tabs;
                })}
              </div>
            </div>
            <div
              className="panel-txtarea-blk ost-txt tab-content"
              id="nav-tabContent"
            >
              {tabTextarea.map((tabField) => {
                // console.log(tabField);
                return tabField;
              })}
            </div>
          </div>
        </>
      );
    } else {
      return <p>Loading...</p>;
    }
  };

  const generateVisualAssetIllustration = () => {
    if (
      templateModel !== undefined &&
      templateModel != null &&
      templateModel != ""
    ) {
      let tabHead = [];
      let tabTextarea = [];
      let OSTArray = Object.fromEntries(
        Object.entries(JSON.parse(templateModel)).filter(([key]) =>
          key.includes("IL")
        )
      );
      var templateModelObj = JSON.parse(templateModel);
      Object.entries(OSTArray).map((i, item) => {
        if (i[1].Contenttype == "Illustration") {
          const ostName = i[0].replace("IL0", "");
          var array = ostName.split("IL");
          tabHead.push(
            <div
              className={`panel-label cm-gen-spec cm-full-lst-container ${
                array == 1 && "active"
              }`}
              title={`Illustration ${array}`}
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target={`#nav-il${array}`}
            >
              <p>{`Illustration ${array}`}</p>
            </div>
          );
          tabTextarea.push(
            <div
              className={`tab-pane  show ${array == 1 && "active"}`}
              id={`nav-il${array}`}
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="img-list">
                <div className="grid-box">
                  {templateasseetIllustration != undefined ? (
                    templateasseetIllustration.map((illustration, index) => {
                      return (
                        <div
                          onClick={() => changeVisualAsset(illustration, i[0])}
                          className={`grid-list ${
                            templateModelObj[i[0]].Contentparams
                              .contentdescription == illustration
                              ? "active"
                              : ""
                          }`}
                        >
                          <div className="tick-active">
                            <img src="assets/img/asset-tick.svg" />
                          </div>
                          <img src={illustration} />
                        </div>
                      );
                    })
                  ) : (
                    <p>Loading ...</p>
                  )}
                </div>
              </div>
            </div>
          );
        }
      });

      return (
        <>
          <div
            className="tab-pane show active"
            id="cm-heading"
            role="tabpanel"
            aria-labelledby="heading-tab"
          >
            <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
              <div
                className="d-flex cm-full-lst-wrapper nav nav-tabs border-0 flex-nowrap"
                id="nav-tab"
                role="tablist"
              >
                {tabHead.map((tabs) => {
                  return tabs;
                })}
              </div>
            </div>
            <div
              className="panel-txtarea-blk ost-txt tab-content"
              id="nav-tabContent"
            >
              {tabTextarea.map((tabField) => {
                // console.log(tabField);
                return tabField;
              })}
            </div>
          </div>
        </>
      );
    } else {
      return <p>Loading...</p>;
    }
  };

  const generateVisualAssetVideos = () => {
    if (
      templateModel !== undefined &&
      templateModel != null &&
      templateModel != ""
    ) {
      let tabHead = [];
      let tabTextarea = [];
      let OSTArray = Object.fromEntries(
        Object.entries(JSON.parse(templateModel)).filter(([key]) =>
          key.includes("VD")
        )
      );
      var templateModelObj = JSON.parse(templateModel);
      Object.entries(OSTArray).map((i, item) => {
        if (i[1].Contenttype == "Video") {
          const ostName = i[0].replace("VD0", "");
          var array = ostName.split("VD");
          tabHead.push(
            <div
              className={`panel-label cm-gen-spec cm-full-lst-container ${
                array == 1 && "active"
              }`}
              title={`Video ${array}`}
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target={`#nav-vd${array}`}
            >
              <p>{`Video ${array}`}</p>
            </div>
          );
          tabTextarea.push(
            <div
              className={`tab-pane  show ${array == 1 && "active"}`}
              id={`nav-vd${array}`}
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="img-list">
                <div className="grid-box">
                  {templateAssetVideos != undefined ? (
                    templateAssetVideos.map((asset, index) => {
                      let image = asset.split(".")[0] + ".png";

                      return (
                        <div
                          onClick={() => changeVisualAsset(asset, i[0])}
                          className={`grid-list ${
                            templateModelObj[i[0]].Contentparams
                              .contentdescription == asset
                              ? "active"
                              : ""
                          }`}
                        >
                          <div className="tick-active">
                            <img src="assets/img/asset-tick.svg" />
                          </div>
                          {/* <img
                            src={image
                            }
                          /> */}
                          <video src={asset}>
                            {/* <source src="myVideo.webm" type="video/webm" /> */}
                            {/* <source src={asset} type="video/mp4" /> */}
                            <p>Your browser doesn't support HTML5 video.</p>
                          </video>
                        </div>
                      );
                    })
                  ) : (
                    <p>Loading ...</p>
                  )}
                  {/* {TEMPLATEVIDEOS.map((video, index) => {
                    return (
                      <div
                        onClick={() => changeVisualAsset(video, i[0])}
                        className={`grid-list ${
                          templateModelObj[i[0]].Contentparams
                            .contentdescription == video
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="tick-active">
                          <img src="assets/img/asset-tick.svg" />
                        </div>
                        <video width="320" height="240" autoPlay={false}>
                          <source src={video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    );
                  })} */}
                </div>
              </div>
            </div>
          );
        }
      });

      return (
        <>
          <div
            className="tab-pane show active"
            id="cm-heading"
            role="tabpanel"
            aria-labelledby="heading-tab"
          >
            <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
              <div
                className="d-flex cm-full-lst-wrapper nav nav-tabs border-0 flex-nowrap"
                id="nav-tab"
                role="tablist"
              >
                {tabHead.map((tabs) => {
                  return tabs;
                })}
              </div>
            </div>
            <div
              className="panel-txtarea-blk ost-txt tab-content"
              id="nav-tabContent"
            >
              {tabTextarea.map((tabField) => {
                // console.log(tabField);
                return tabField;
              })}
            </div>
          </div>
        </>
      );
    } else {
      return <p>Loading...</p>;
    }
  };

  // On file upload (click the upload button)
  const onFileUpload = () => {
    setmodalLoading(false);
    setost_error(false);
    setuploadResponse("");
    settemplateModel(undefined);
    setshowPreviewVideo(false);
    setshowTemplateModel(false);
    setbgColor("#000000");
    settextColor("#ffffff");
    if (selectedFile != undefined) {
      setloading(true);
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append("file", selectedFile);

      // Details of the uploaded file
      // console.log(selectedFile);

      const headers = {
        "Content-Type": "multipart/form-data",
        template_type: selectedTemplateType,
        user_id: getUserItem("id"),
        upload: onlyValidate ? 0 : 1,
      };
      console.log(selectedFile, "selectedFile");
      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(percentCompleted);
        },
        headers: {
          "Content-Type": "multipart/form-data",
          template_type: selectedTemplateType,
          user_id: getUserItem("id"),
          upload: onlyValidate ? 0 : 1,
          file_name: selectedFile.name,
        },
      };

      console.log(config);

      // Request made to the backend api
      // Send formData object
      Axioslib.post("media/uploadTemplate", formData, config)
        .then(async (response) => {
          console.log(response, "resss111");
          if (response.data != undefined) {
            console.log(response, "resss");
            if (response.data.StatusCode === 101) {
              setTempValid(true);
              setTempError(response.data.message);
            } else {
              if (response.data.response != undefined) {
                if (
                  response.data.response.mediaurl != undefined &&
                  response.data.response.mediaurl != null &&
                  response.data.response.mediaurl != ""
                ) {
                  setmodalLoading(true);
                  let ostLength = {
                    heading_length:
                      response.data.response.ost_lengths.Heading_Lengths,
                    paragraph_length:
                      response.data.response.ost_lengths.Paragraph_Lengths,
                    bullet_length:
                      response.data.response.ost_lengths.Bulletpoint_Lengths,
                  };
                  setost_length(ostLength);
                  setno_of_images(response.data.response.no_of_images);
                  setno_of_illustrations(
                    response.data.response.no_of_illustrations
                  );
                  setno_of_videos(response.data.response.no_of_videos);
                  settemplateResponse(response.data.response);
                  if (response.data.response.tid != undefined) {
                    let existingTemplate = await findTemplateById(
                      response.data.response.tid
                    );
                    console.log(
                      existingTemplate,
                      existingTemplate.data,
                      existingTemplate.status
                    );
                    console.log(
                      existingTemplate.status === 200 &&
                        existingTemplate.data.data != "" &&
                        existingTemplate.data.data != null &&
                        existingTemplate.data.data != undefined
                    );
                    if (
                      existingTemplate.status === 200 &&
                      existingTemplate.data.result.data.length > 0
                    ) {
                      console.log(
                        existingTemplate.data.result.data[0],
                        "dataaaaaaaaaaa"
                      );
                      settAlreadyExist(true);
                      settdata(existingTemplate.data.result.data[0]);
                      setselectedFile(undefined);
                      setshowTemplateModel(false);
                    } else {
                      generateTemplateByData(response.data.response, "new");
                      // let data = await CreateTemplateData(response.data.response);
                      // console.log(data);
                      // if (data != null && data != "" && data != undefined) {
                      //   setshowTemplateModel(true);
                      //   getFonts();
                      //   settemplateModel(JSON.stringify(data));
                      //   setmodalLoading(false);
                      //   saveTemplate(response.data.response);
                      //   // console.log(data);
                      // } else {
                      //   setshowTemplateModel(false);
                      // }
                    }
                  }
                } else {
                  //show warning
                }
              } else {
                //show warning
              }
            }
          } else {
            //show warning
          }
          setuploadResponse(JSON.stringify(response, undefined, 4));
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
          setuploadResponse(JSON.stringify(err, undefined, 4));
        });
    } else {
      showToaster("Please choose a file", "warning");
    }
  };

  const assetUpload = () => {
    var header;
    if (assetUploadType == "IMG") {
      header = {
        "content-type": "multipart/form-data",
        media_type: "IMAGE",
        user_id: getUserItem("id"),
        file_name: uploadedAsset.name,
        private: 1,
      };
    } else if (assetUploadType == "ILLU") {
      header = {
        "content-type": "multipart/form-data",
        media_type: "LOGO",
        user_id: getUserItem("id"),
        file_name: uploadedAsset.name,
        tag_names: "",
        private: 1,
      };
    } else if (assetUploadType == "VID") {
      header = {
        "content-type": "multipart/form-data",
        media_type: "VIDEO",
        user_id: getUserItem("id"),
        file_name: uploadedAsset.name,
        tag_names: "uploadedAsset.name",
        private: 1,
      };
    }

    setloading(true);
    try {
      let data = new FormData();
      const config = {
        timeout: 1000 * 50000,
        headers: header,
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(percentCompleted);
        },
      };
      //console.log(uploadedFile);
      data.append("file", uploadedAsset != undefined ? uploadedAsset : null);
      uploadMediaFile(data, config).then(async (response) => {
        try {
          if (response.status == 101) {
            showToaster("Failed to upload. Try again!", "warning");
            setloading(false);
          } else if (response.status == 400) {
            showToaster("Asset already uploaded!", "warning");
            setshowAssetUploadModel(false);
            setloading(false);
            setuploadedAsset(undefined);
          } else if (response.status == 200) {
            console.log(response);
            if (
              response.data != undefined &&
              response.data != "" &&
              response.data != null
            ) {
              let saveasset = await saveAsset(response.data, assetUploadType);
              console.log(saveasset);
              if (saveasset != undefined) {
                if (saveasset.data.result.data != undefined) {
                  if (assetUploadType == "IMG") {
                    if (templateAsset == undefined) {
                      let a = [];
                      a.push(saveasset.data.result.data.assetUrl);
                      settemplateAsset(a);
                    } else {
                      let a = templateAsset;
                      a.push(saveasset.data.result.data.assetUrl);
                      settemplateAsset(a);
                    }
                  } else if (assetUploadType == "ILLU") {
                    if (templateasseetIllustration == undefined) {
                      let a = [];
                      a.push(saveasset.data.result.data.assetUrl);
                      settemplateasseetIllustration(a);
                    } else {
                      let a = templateasseetIllustration;
                      a.push(saveasset.data.result.data.assetUrl);
                      settemplateasseetIllustration(a);
                    }
                  } else if (assetUploadType == "VID") {
                    if (templateAssetVideos == undefined) {
                      let a = [];
                      a.push(saveasset.data.result.data.assetUrl);
                      settemplateAssetVideos(a);
                    } else {
                      let a = templateAssetVideos;
                      a.push(saveasset.data.result.data.assetUrl);
                      settemplateAssetVideos(a);
                    }
                  }
                }
              }

              setuploadedAsset(undefined);
            }
            showToaster("Successfully uploaded", "success");
            setshowAssetUploadModel(false);
            setloading(false);
          }
        } catch (error) {
          showToaster("Failed to upload. Try again!", "warning");
          setshowAssetUploadModel(false);
          setloading(false);
          setuploadedAsset(undefined);
        }
      });
    } catch (error) {
      showToaster("Failed to upload. Try again!", "warning");
      setshowAssetUploadModel(false);
      setloading(false);
      setuploadedAsset(undefined);
    }
  };

  const generateTemplateByData = async (Responsedata, type) => {
    setloading(true);

    if (
      templateAsset != undefined &&
      templateasseetIllustration != undefined &&
      templateAssetVideos != undefined
    ) {
      let data = await CreateTemplateData(Responsedata);
      console.log(data);
      if (data != null && data != "" && data != undefined) {
        setshowTemplateModel(true);
        getFonts();
        settemplateModel(JSON.stringify(data));
        setmodalLoading(false);
        // let OrgId = (OrgId = listStatus.orgId !== 0 ? listStatus.orgId : "");
        // if (TemplateFeature === false) {
        //   OrgId = UserId;
        // } else {
        //   OrgId = listStatus.orgId !== 0 ? listStatus.orgId : "";
        // }
        let OrgId;
        if (OrganiId !== "621c84953a7e2941763a1f35") {
          OrgId = userId;
        } else {
          OrgId = listStatus.orgId !== 0 ? listStatus.orgId : "";
        }
        console.log(OrgId, "OrgId");
        saveTemplate(Responsedata, selectedTemplateType, type, OrgId);
        // console.log(data);
        setloading(false);
      } else {
        setshowTemplateModel(false);
        setloading(false);
      }
    } else {
      setloading(false);
      showToaster("Failed to load assets. Please try again", "warning");
    }
  };

  return (
    <div>
      {/*  */}
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <PageLoader showLoader={loading} />
        <div className="main-wrapper">
          <Header />
          <div className="upload-temp">
            <div className="row mb-4">
              <div className="col-md-12 text-center">
                <h2>Upload Template</h2>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="cm-style-upload-holder">
                  <div className="cm-style-container">
                    <div className="cc-csv-btn">
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginBottom: "5px",
                        }}
                      >
                        {selectedFile != undefined && selectedFile.name}
                      </p>
                      <label className="btn btn-primary b-logo-up">
                        Upload your template
                        <input
                          type="file"
                          onChange={(e) => onFileChange()}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          id="assestUpload"
                          accept=".zip,.rar,.7zip"
                          className="d-none"
                        />
                      </label>
                    </div>
                    <div className="cm-style-spec mt-3">
                      File format allowed (Zip)
                    </div>
                    {/* <div className="stok-selct">
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="q11"
                  onChange={() => setPublicPrivateOption(1)}

                  checked={publicPrivateOption === 1 ? true : false}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Private</span>
            </label>
            <label className="templt-radio-holder">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="q11"
                  onChange={() => setPublicPrivateOption(2)}

                  checked={publicPrivateOption === 2 ? true : false}
                />
                <span className="checkmark"></span>
              </label>
              <span className="templt-label">Public</span>
            </label>
          </div> */}
                  </div>
                </div>
                <div className="field-block">
                  {/* {TemplateFeature==true && ( */}
                  <>
                  {(AdminRole == 1 ||AdminRole == 0)&&
                    OrganiId === "621c84953a7e2941763a1f35" && (
                      <>
                        <label className="custom-label">Organization Name</label>
                        <div className="srch-ft-selector w-100 p-0 drop-custom">
                          <Select
                            className="custom-input"
                            styles={customStyles}
                            value={listStatus}
                            onChange={(e) => handleChangeStatus(e)}
                            options={statusOption}
                          />
                        </div>
                      </>
                    )}
                    {/* {AdminRole == 0 &&
                    OrganiId === "621c84953a7e2941763a1f35" && (
                      <>
                        <label className="custom-label">Organization Name</label>
                        <div className="srch-ft-selector w-100 p-0 drop-custom">
                          <Select
                            className="custom-input"
                            styles={customStyles}
                            value={listStatus}
                            onChange={(e) => handleChangeStatus(e)}
                            options={statusOption}
                          />
                        </div>
                      </>
                    )} */}
                    {AdminRole == 0 &&
                    OrganiId === "" && (
                      <>
                        <label className="custom-label">Organization Name</label>
                        <div className="srch-ft-selector w-100 p-0 drop-custom">
                          <Select
                            className="custom-input"
                            styles={customStyles}
                            value={listStatus}
                            onChange={(e) => handleChangeStatus(e)}
                            options={statusOption}
                          />
                        </div>
                      </>
                    )}
                  </>
                  {/* )} */}
                  <label className="custom-label">Template Type</label>
                  <div className="custom-select mb-2">
                    <select
                      onChange={(e) => changeTemplateType(e)}
                      className="form-select form-field"
                    >
                      <option value="motion_graphics">motion_graphics</option>
                      <option value="slide_title">Slide title</option>
                    </select>
                  </div>
                </div>
                <div className="field-block">
                  <label className="custom-label"></label>
                  <div className="mb-2">
                    <div className="validate-checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={onlyValidate}
                        onChange={() => {
                          setonlyValidate(!onlyValidate);
                        }}
                      />{" "}
                      Only validate the template
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-4">
                  <button
                    type="button"
                    className="btn btn-primary  me-2 rounded-0"
                    onClick={() => onFileUpload()}
                  >
                    Upload
                  </button>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="panel-txtarea-blk">
                  <h3>Response Data</h3>
                  <textarea
                    className="panel-txtarea"
                    value={uploadResponse}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}

      {/* <div style={{ textAlign: "center" }}>
        <h3 style={{ fontSize: "30px", padding: "50px 0px 20px" }}>
          Upload template
        </h3>
        <p style={{ color: "red" }}>
          Note : Please choose a zip file to upload
        </p>

        <div
          style={{
            width: "50%",
            textAlign: "left",
            margin: "0 auto",
            marginTop: "30px",
          }}
        >
          <input
            type="file"
            onChange={(e) => onFileChange(e)}
            accept=".zip,.rar,.7zip"
          />
          <br />
          <label style={{ lineHeight: "40px", fontWeight: "600" }}>
            Template Type :{" "}
          </label>
          <select onChange={(e) => changeTemplateType(e)}>
            <option value="motion_graphics">motion_graphics</option>
            <option value="slide_title">Slide title</option>
          </select>
          <br />
          <button onClick={() => onFileUpload()}>Upload!</button>
          {loading && (
            <p style={{ color: "green" }}>Uploading, Please wait...</p>
          )}
        </div>
        <div>
          <p style={{ textAlign: "center" }}>Response data</p>
          <textarea
            style={{
              height: "380px",
              width: "80%",
              marginTop: "20px",
              backgroundColor: "lightgrey",
            }}
            value={uploadResponse}
          ></textarea>
        </div>
      </div> */}
      {/* Modal */}
      <Modal
        isOpen={showTemplateModel}
        fade={true}
        centered
        className={
          DisplayMode
            ? "modal-dialog  modal-dialog-centered custom-modal dark ost-asset-modal modal-xl"
            : "modal-dialog  modal-dialog-centered custom-modal ost-asset-modal modal-xl"
        }
      >
        <ModalBody>
          {generatePreviewStatus ? (
            <>
              <div
                className="close-priview"
                data-bs-dismiss="modal"
                onClick={() => {
                  setgeneratePreviewStatus(false);
                  setshowTemplateModel(false);
                  setmediaUrlArr(undefined);
                  setmodalLoading(false);
                  setshowPreviewVideo(false);
                }}
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g data-name="Layer 2">
                    <g data-name="close">
                      <rect
                        width="24"
                        height="24"
                        transform="rotate(180 12 12)"
                        opacity="0"
                      />
                      <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="template-preview">
                <p>Please wait ...</p>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${progressCount}%` }}
                    ></div>
                  </div>
                  <span style={{ color: "white" }}>{progressCount}%</span>
                  {retry && (
                    <button
                      type="button"
                      className="btn btn-primary   rounded-0"
                      onClick={() => {
                        PreviewGenerate();
                      }}
                    >
                      Retry
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : showPreviewVideo ? (
            <>
              <div className="ost-video-preview">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div
                    className="close-priview"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setprogressCount(0);
                      setgeneratePreviewStatus(false);
                      setshowTemplateModel(false);
                      setmediaUrlArr(undefined);
                      setmodalLoading(false);
                      setshowPreviewVideo(false);
                    }}
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g data-name="Layer 2">
                        <g data-name="close">
                          <rect
                            width="24"
                            height="24"
                            transform="rotate(180 12 12)"
                            opacity="0"
                          />
                          <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>

                {mediaUrlArr != undefined ? (
                  <>
                    {mediaUrlArr.PreviewUrl != undefined &&
                    mediaUrlArr.PreviewUrl != "" &&
                    mediaUrlArr.PreviewUrl != null ? (
                      <Videoplayer
                        videoUrl={mediaUrlArr.PreviewUrl}
                        thumbnailUrl={mediaUrlArr.ThumbnailUrl}
                      />
                    ) : (
                      <p>Something went wrong!</p>
                    )}
                  </>
                ) : (
                  <p>Something went wrong!</p>
                )}
                <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-4 mb-4">
                  {/* <button
                  type="button"
                  className="btn btn-outline rounded-0 me-2 modal-toggle"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setshowPreviewVideo(false)
                    setmediaUrlArr(undefined)
                  }}
                >
                  Edit
                </button> */}
                  <button
                    type="button"
                    className="btn btn-primary   rounded-0"
                    onClick={() => {
                      setprogressCount(0);
                      setshowTemplateModel(false);
                      setmediaUrlArr(undefined);
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  PreviewGenerate();
                }}
              >
                <h2>Add data for template</h2>

                <div className="ost-asset-wrap">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div
                      className="close-priview"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setprogressCount(0);
                        setgeneratePreviewStatus(false);
                        setshowTemplateModel(false);
                        setmediaUrlArr(undefined);
                        setmodalLoading(false);
                        setshowPreviewVideo(false);
                      }}
                    >
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g data-name="Layer 2">
                          <g data-name="close">
                            <rect
                              width="24"
                              height="24"
                              transform="rotate(180 12 12)"
                              opacity="0"
                            />
                            <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample">
                    <div className="accordion-list">
                      <h3 id="headingAS">
                        <a
                          href=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseAudioScript"
                          aria-expanded="true"
                          aria-controls="collapseAudioScript"
                        >
                          Audio Script
                        </a>
                      </h3>
                      <div
                        id="collapseAudioScript"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingAS"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body pb-0">
                          {generateAudioScriptField()}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-list">
                      <h3 id="headingDuration">
                        <a
                          href=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseDuration"
                          aria-expanded="true"
                          aria-controls="collapseDuration"
                        >
                          Duration
                        </a>
                      </h3>
                      <div
                        id="collapseDuration"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingDuration"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body pb-0">
                          {generateDurationField()}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-list">
                      <h3 id="headingStyle">
                        <a
                          href=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseStyle"
                          aria-expanded="true"
                          aria-controls="collapseStyle"
                        >
                          Style
                        </a>
                      </h3>
                      <div
                        id="collapseStyle"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingStyle"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body pb-0">
                          {generateStyleField()}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-list">
                      <h3 id="headingOne">
                        <a
                          href=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          OST
                        </a>
                      </h3>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body pb-0">
                          {generateOstFields()}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-list">
                      <h3 id="headingTwo">
                        <a
                          href=""
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          VIsual Assets
                        </a>
                      </h3>

                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body pb-0">
                          <div id="accordionImage">
                            {no_of_images > 0 && (
                              <div className="accordion-list">
                                <h3
                                  id="headingOneImage"
                                  className="d-flex justify-content-between"
                                >
                                  <a
                                    href=""
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOneImage"
                                    aria-expanded="true"
                                    aria-controls="collapseOneImage"
                                    className="flex-fill"
                                  >
                                    Images
                                  </a>
                                  <span
                                    style={{
                                      borderBottom: ".5px solid #4c4c4c",
                                    }}
                                    onClick={() => {
                                      setshowAssetUploadModel(true);
                                      setassetUploadType("IMG");
                                    }}
                                  >
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      stroke="#c0c2c3"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6.04688 5.76485L9 2.8125L11.9531 5.76485"
                                        stroke=""
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                      <path
                                        d="M9 10.6874V2.81445"
                                        stroke=""
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                      <path
                                        d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875"
                                        stroke=""
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </h3>

                                <div
                                  id="collapseOneImage"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOneImage"
                                  data-bs-parent="#accordionImage"
                                >
                                  <div className="accordion-body pb-0">
                                    {generateVisualAssets()}
                                  </div>
                                </div>
                              </div>
                            )}
                            {no_of_illustrations > 0 && (
                              <div className="accordion-list">
                                <h3
                                  id="headingOneIllu"
                                  className="d-flex justify-content-between"
                                >
                                  <a
                                    href=""
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOneIllu"
                                    aria-expanded="true"
                                    aria-controls="collapseOneIllu"
                                    className="flex-fill"
                                  >
                                    Illustrations
                                  </a>
                                  <span
                                    style={{
                                      borderBottom: ".5px solid #4c4c4c",
                                    }}
                                    onClick={() => {
                                      setshowAssetUploadModel(true);
                                      setassetUploadType("ILLU");
                                    }}
                                  >
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      stroke="#c0c2c3"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6.04688 5.76485L9 2.8125L11.9531 5.76485"
                                        stroke=""
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                      <path
                                        d="M9 10.6874V2.81445"
                                        stroke=""
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                      <path
                                        d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875"
                                        stroke=""
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </h3>
                                <div
                                  id="collapseOneIllu"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOneIllu"
                                  data-bs-parent="#accordionIllu"
                                >
                                  <div className="accordion-body pb-0">
                                    {generateVisualAssetIllustration()}
                                  </div>
                                </div>
                              </div>
                            )}
                            {no_of_videos > 0 && (
                              <div className="accordion-list">
                                <h3
                                  id="headingOneVideo"
                                  className="d-flex justify-content-between"
                                >
                                  <a
                                    href=""
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOneVideo"
                                    aria-expanded="true"
                                    aria-controls="collapseOneVideo"
                                    className="flex-fill"
                                  >
                                    Videos
                                  </a>
                                  <span
                                    style={{
                                      borderBottom: ".5px solid #4c4c4c",
                                    }}
                                    onClick={() => {
                                      setshowAssetUploadModel(true);
                                      setassetUploadType("VID");
                                    }}
                                  >
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      stroke="#c0c2c3"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6.04688 5.76485L9 2.8125L11.9531 5.76485"
                                        stroke=""
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                      <path
                                        d="M9 10.6874V2.81445"
                                        stroke=""
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                      <path
                                        d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875"
                                        stroke=""
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </h3>
                                <div
                                  id="collapseOneVideo"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOneVideo"
                                  data-bs-parent="#accordionVideo"
                                >
                                  <div className="accordion-body pb-0">
                                    {generateVisualAssetVideos()}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* {generateVisualAssets()} */}
                          {/* {generateVisualAssetIllustration()} */}
                          {/* {generateVisualAssetVideos()} */}
                          {/* {templateModel !== undefined &&
                          templateModel != null &&
                          templateModel != "" && (
                            <>
                              <div
                                className="tab-pane show active"
                                id="cm-heading"
                                role="tabpanel"
                                aria-labelledby="heading-tab"
                              >
                                <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
                                  <div
                                    className="d-flex cm-full-lst-wrapper nav nav-tabs border-0 flex-nowrap"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    {Object.entries(
                                      Object.fromEntries(
                                        Object.entries(JSON.parse(templateModel)).filter(
                                          ([key]) =>
                                            key.includes("IM") ||
                                            key.includes("IL") ||
                                            key.includes("VD")
                                        )
                                      )
                                    ).map((i, item) => {
                                      if (i[1].Contenttype == "Image") {
                                        const ostName = i[0].replace("IM0", "");
                                        var array = ostName.split("IM");

                                        return (
                                          <div
                                            className={`panel-label cm-gen-spec cm-full-lst-container ${
                                              array == 1 && "active"
                                            }`}
                                            title={`Image ${array}`}
                                            id="nav-home-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target={`#nav-im${array}`}
                                          >
                                            <p>{`Image ${array}`}</p>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                                <div
                                  className="panel-txtarea-blk ost-txt tab-content"
                                  id="nav-tabContent"
                                >
                                  
                                    {Object.entries(
                                      Object.fromEntries(
                                        Object.entries(JSON.parse(templateModel)).filter(
                                          ([key]) =>
                                            key.includes("IM") ||
                                            key.includes("IL") ||
                                            key.includes("VD")
                                        )
                                      )
                                    ).map((i, item) => {
                                      var templateModelObj = JSON.parse(templateModel)
                                      if (i[1].Contenttype == "Image") {
                                        const ostName = i[0].replace("IM0", "");
                                        var array = ostName.split("IM");
                                        return <div
                                            className={`tab-pane  show ${array == 1 && "active"}`}
                                            id={`nav-im${array}`}
                                            role="tabpanel"
                                            aria-labelledby="nav-home-tab"
                                          >
                                            <div className="img-list">
                                              <div className="grid-box">
                                                {TEMPLATEIMAGES.map((image, index) => {
                                                  
                                                  return (
                                                    
                                                    <div
                                                      onClick={() => changeVisualAsset(image, i[0])}
                                                      className={`grid-list ${
                                                        templateModelObj[i[0]].Contentparams
                                                          .contentdescription == image
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div className="tick-active">
                                                        <img src="assets/img/asset-tick.svg" />
                                                      </div>
                                                      <img src={image} />
                                                      
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                             <p style={{color:"red"}}> {
                                                      templateModelObj[i[0]].Contentparams
                                                      .contentdescription
                                                    } </p>
                                            </div>
                                          </div>
                                      }
                                    })}
                                  
                                </div>
                              </div>
                            </>
                          )} */}
                          {/* <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Images
                        </label>
                        <div
                          className="tab-pane show active"
                          id="cm-heading"
                          role="tabpanel"
                          aria-labelledby="heading-tab"
                        >
                          <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
                            <div
                              className="d-flex cm-full-lst-wrapper nav nav-tabs border-0 flex-nowrap"
                              id="nav-tab"
                              role="tablist"
                            >
                              <div
                                className="panel-label cm-gen-spec cm-full-lst-container active"
                                title="OST 01"
                                id="nav-home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-home1"
                              >
                                <p>Heading 01 </p>
                                <div className="cm-del-icon">
                                  <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                                      fill=""
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div
                                className="panel-label cm-gen-spec cm-full-lst-container "
                                title="OST 02"
                                id="nav-profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-profile1"
                              >
                                <p>Heading 02</p>
                                <div className="cm-del-icon">
                                  <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                                      fill=""
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="panel-txtarea-blk ost-txt tab-content"
                            id="nav-tabContent"
                          >
                            <div
                              className="tab-pane  show active"
                              id="nav-home1"
                              role="tabpanel"
                              aria-labelledby="nav-home-tab"
                            >
                              <div className="img-list">
                                <div className="grid-box">
                                  <div className="grid-list active">
                                    <div className="tick-active">
                                      <img src="assets/img/asset-tick.svg" />
                                    </div>
                                    <img src="https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Images/YlES0PvDgyc_22122021113033029912.jpg" />
                                  </div>

                                  <div className="grid-list">
                                    <div className="tick-active">
                                      <img src="assets/img/asset-tick.svg" />
                                    </div>
                                    <img src="https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Images/YlES0PvDgyc_22122021113033029912.jpg" />
                                  </div>

                                  <div className="grid-list">
                                    <img src="https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Images/YlES0PvDgyc_22122021113033029912.jpg" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane "
                              id="nav-profile1"
                              role="tabpanel"
                              aria-labelledby="nav-profile-tab"
                            >
                              <textarea
                                placeholder="Enter OST"
                                className="panel-txtarea"
                              >
                                22222
                              </textarea>
                            </div>
                          </div>
                        </div> */}
                          {/* <div className="img-list">
            <div className="grid-box">
              <div className="grid-list active">
                <div className="tick-active">
                  <img src="assets/img/asset-tick.svg" />
                </div>
                <img src="https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Images/YlES0PvDgyc_22122021113033029912.jpg" />
              </div>

              <div className="grid-list">
                <div className="tick-active">
                  <img src="assets/img/asset-tick.svg" />
                </div>
                <img src="https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Images/YlES0PvDgyc_22122021113033029912.jpg" />
              </div>

              <div className="grid-list">
                <img src="https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Images/YlES0PvDgyc_22122021113033029912.jpg" />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center mb-3">
            <span className="text-or">Or</span>
          </div>
          <div className="prev-img">
            <img src="https://oliveproduction-automation-storages.s3.eu-central-1.amazonaws.com/Images/YlES0PvDgyc_22122021113033029912.jpg" />
          </div>

          <div className="">
            <label for="exampleFormControlInput1" className="form-label">
              image url
            </label>
            <div className="d-flex align-items-center">
              <input
                type="email"
                className="form-control flex-grow-1 me-3"
                id="exampleFormControlInput1"
                placeholder=""
              />
              <div className="prev-btn">
                <button
                  type="button"
                  className="btn btn-primary rounded-0"
                >
                  Preview
                </button>
              </div>
            </div>
          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary  me-2 rounded-0"
                    onClick={() => {
                      // PreviewGenerate();
                    }}
                  >
                    Preview
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline rounded-0 modal-toggle"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setshowTemplateModel(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={tempValid}
        fade={true}
        centered
        className={
          DisplayMode
            ? "modal-dialog  modal-dialog-centered custom-modal  dark"
            : "modal-dialog  modal-dialog-centered custom-modal "
        }
      >
        <ModalBody>
          <div className="pt-3 mb-5">
            <p>
              Template Validation Error:-
              <b>{tempError != undefined && tempError}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between footer-btn flex-wrap">
            <span></span>
            <div className="d-flex upload-info align-items-center">
              <button
                type="button"
                className="btn btn-primary  rounded-0"
                onClick={() => {
                  setTempValid(false);
                  setTempError(undefined);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={tAlreadyExist}
        fade={true}
        centered
        className={
          DisplayMode
            ? "modal-dialog  modal-dialog-centered custom-modal  dark"
            : "modal-dialog  modal-dialog-centered custom-modal "
        }
      >
        <ModalBody>
          <div className="pt-3 mb-5">
            <p>
              This template is already uploaded by{" "}
              <b>{tdata != undefined && tdata.emailid}</b> on{" "}
              <b>
                {tdata != undefined &&
                  `${new Date(tdata.addedDate).toDateString()}`}
              </b>
              . Do you want to continue?{" "}
            </p>
          </div>
          <div className="d-flex justify-content-between footer-btn flex-wrap">
            {/* <button type="button" className="btn btn-primary  rounded-0">
              Preview
            </button> */}
            <button
              type="button"
              className="btn btn-outline me-2 rounded-0 modal-toggle"
              data-bs-dismiss="modal"
              onClick={() => {
                settAlreadyExist(false);
                settdata(undefined);
              }}
            >
              Cancel
            </button>
            <div className="d-flex upload-info align-items-center">
              <button
                type="button"
                className="btn btn-primary  rounded-0"
                onClick={() => {
                  settAlreadyExist(false);
                  settdata(undefined);
                  generateTemplateByData(templateResponse, "exist");
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showAssetUploadModel}
        fade={true}
        centered
        className={
          DisplayMode
            ? "modal-dialog  modal-dialog-centered custom-modal dark ost-asset-modal modal-xl"
            : "modal-dialog  modal-dialog-centered custom-modal ost-asset-modal modal-xl"
        }
      >
        <ModalBody>
          <div
            className="close-priview"
            data-bs-dismiss="modal"
            onClick={() => {
              setshowAssetUploadModel(false);
              setassetUploadType(undefined);
              setuploadedAsset(undefined);
            }}
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g data-name="Layer 2">
                <g data-name="close">
                  <rect
                    width="24"
                    height="24"
                    transform="rotate(180 12 12)"
                    opacity="0"
                  />
                  <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                </g>
              </g>
            </svg>
          </div>
          <div className="aspt-blk">
            <div className={"aspt-upload-holder"}>
              <div className="aspt-container">
                {
                  <div className="cc-csv-btn">
                    <label className="btn btn-primary b-logo-up">
                      {assetUploadType == "IMG"
                        ? "Upload your image"
                        : assetUploadType == "ILLU"
                        ? "Upload your icon"
                        : assetUploadType == "ILLU"
                        ? "Upload your logo"
                        : assetUploadType == "VID"
                        ? "Upload your video"
                        : ""}
                      <input
                        type="file"
                        className="d-none"
                        accept={
                          assetUploadType == "IMG"
                            ? ".jpg,.jpeg,.png,.JPG,.JPEG"
                            : assetUploadType == "ILLU"
                            ? ".PNG,.ico,.ICO"
                            : assetUploadType == "VID" && ".mp4,.MP4"
                        }
                        onChange={(e) => {
                          setuploadedAsset(e.target.files[0]);
                        }}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        id="assestUpload"
                      />
                    </label>
                  </div>
                }
                {uploadedAsset != undefined && (
                  <label className="cover-image">
                    <p>{uploadedAsset.name}</p>
                  </label>
                )}
              </div>
            </div>
            {
              <div className="upload-controls d-flex text-center my-2 align-items-center justify-content-center">
                <button
                  type="button"
                  onClick={() => {
                    assetUpload();
                  }}
                  className="btn btn-primary btn-sm me-2 pro-submt-btn border-0"
                  disabled={uploadedAsset != undefined ? false : true}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setshowAssetUploadModel(false);
                    setassetUploadType(undefined);
                    setuploadedAsset(undefined);
                  }}
                  className="btn btn-danger me-2 btn-sm pro-submt-btn"
                >
                  Close
                </button>
              </div>
            }

            <div className="img-file-spec">
              <div className="mt-3 text-center red-text error-msg">
                {/* {uploadErroMessage} */}
              </div>
              {/* {illuUploadOption === 1 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be ico and File size is less than 25 mb
              </div>
            ) : uploadOption === 3 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be ntp and File size is less than 25 mb
              </div>
            ) : fontOption === 1 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be ttf and File size is less than 25 mb
              </div>
            ) : uploadOption === 2 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be jpeg, jpg, png and File size is less than
                25 mb
              </div>
            ) : videoOption === 1 ? (
              <div className="cm-style-spec mt-3 text-center">
                File format should be mp4 and File size is less than 25 mb
              </div>
            ) : (
                        <div className="cm-style-spec mt-3 text-center">
                          File format should be jpeg, jpg, png and File size is less than
                          25 mb
                        </div>
                      )} */}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <CustomModal
        isOpen={openUpdateModal}
        Closemodal={setopenUpdateModal}
        Content="The template you attempted to upload looks to have a problem. Please double-check and re-upload."
        Header="Template Preview Generation Failed"
        Buttonlabel="OK"
        Buttonclick={previewFailed}
        // del_id={deleteScreenId}
      />
    </div>
  );
}

export default TemplateUpload;
