import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { DeleteClusterDetails, clusterRefresh, clusterReset, getClusterDetails } from "../../services/api.service";
import CreateCluster from "./CreateCluster";
import VmDetails from "./vmdetails";
import ClusterUsage from "./ClusterUsage";
import { showToaster } from "../../services/helper";
import Header from "../components/header/header";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CustomModal from "../../services/CustomModal";

function ListCluster() {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [loading, setloading] = useState(false);
  const [clusterList, setClusterList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [clusterCount, setClusterCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [newUser, setnewUser] = useState(undefined);
  const [openCreateCluster, setOpenCreateCluster] = useState(false);
  const [openClusterUsage, setOpenClusterUsage] = useState(false);
  const [openvmDetails, setOpenVmDetails] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [deleteClusterId, setDeleteClusterId] = useState(null);
  const [resetClusterId, setResetClusterId] = useState(null);
  const [resetConfirmMsg, setResetConfirmMsg] = useState("");
  const [deleteConfirmMsg, setDeleteConfirmMsg] = useState("");
  const searchRef = useRef("");
  // useEffect(() => {
  //   if(location.search) {
  //     const searchParams = new URLSearchParams(location.search);
  //     let searchVal = searchParams.get('search');
  //     searchVal = searchVal ? searchVal.trim() : '';
  //     if(searchVal) {
  //       setSearch(searchVal);
  //       searchRef.current = searchVal;
  //     }
  //   }
  // }, []);
  const getClusterConfigs = async () => {
    try {
      setloading(true);
      const response = await getClusterDetails();
      if (response.data && response.data.result) {
        setClusterList(response.data.result);
        setClusterCount(response.data.totalCount);
      }
    } catch (error) {
      console.log(error);
    }
    searchRef.current = "";
    setloading(false);
  };
  useEffect(() => {
    getClusterConfigs();
    setnewUser(undefined);
  }, [openCreateCluster]);

  useEffect(() => {
    getClusterConfigs();
  }, [currentPage, isSearch, perPage]);

  const Loader = () => {
    return (
      <div className="loader-wrapper">
        <div className="loader-inner">
          <img src="../../../assets/img/loader.gif"></img>
        </div>
      </div>
    );
  };

  const DeleteClusterDetail = (value) => {
    setloading(true);
    try {
      let filter = {
        rendercluster_name: value.rendercluster_name,
        resource_group: value.resource_group,
        cluster_name: value.cluster_name
      };
      DeleteClusterDetails(filter).then((response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null &&
          response.status == 200
        ) {
          getClusterConfigs();
          setloading(true);
          showToaster("Cluster Deleted successfully", "success");
          // props.closePreview();
        } else {
          console.log(response);
          showToaster(response.message, "warning");
          // setErrorStatus(response.message);
          setloading(true);
        }
      });
    } catch (e) {
      setloading(true);
      showToaster("Something went wrong. Please Try Again", "warning");
      // setErrorStatus(e.response.data.errors);
    }
  };

  const deleteCluster = (id) => {
    const clusterToDelete = clusterList.find((cluster) => cluster._id === id);
    DeleteClusterDetail(clusterToDelete);
  };

  const resetCluster = async (id) => {
    setloading(true);
    await clusterReset(id);
    getClusterConfigs();
  };

  const refreshCluster = async (id) => {
    setloading(true);
    await clusterRefresh(id);
    getClusterConfigs();
  };

  const listClusterData = (data) => {
    return (
      <div className="table-wraper">
        <div className="usage-list-warper template-listing cluster-listing">
          <div className="usage-block mb-3">
            <div className="usage-head-label text-center">Render Cluster Name</div>

            <div className="usage-head-label text-center">Organization Name</div>
            <div className="usage-head-label text-center">Resource Group</div>
            <div className="usage-head-label text-center">Cluster Name</div>
            <div className="usage-head-label text-center">Projects Rendering</div>
            <div className="usage-head-label text-center">Vm Size</div>
            <div className="usage-head-label text-center">Vm Count</div>
            <div className="usage-head-label text-center">Min VM Count</div>
            <div className="usage-head-label text-center">Max VM Count</div>
            <div className="usage-head-label text-center">Max Unit</div>
            <div className="usage-head-label text-center">Status</div>
            <div className="usage-head-label text-center">Delete</div>
          </div>
          {data != undefined &&
            data.map((configData, i) => {
              if (configData.isDelete == 0) {
                return (
                  <div key={i} className="usage-block usg-sub-block mb-3">
                    <div className="usage-head-label text-center">{configData.rendercluster_name}</div>
                    <div className="usage-head-label text-center">{configData.organisationName}</div>
                    <div className="usage-head-label text-center">{configData.resource_group}</div>
                    <div className="usage-head-label text-center">{configData.cluster_name}</div>
                    <div className="usage-head-label text-center">
                      {/* {configData.number_of_render_inprogress > 0 ? ( */}
                      <Link to={`/admin/cluster/${configData._id}/render-list`}>{configData.number_of_render_inprogress}</Link>
                      {/* ) : (
                        <>{configData.number_of_render_inprogress}</>
                      )} */}
                    </div>
                    <div className="usage-head-label text-center">{configData.vm_size}</div>
                    <div className="usage-head-label text-center">{configData.vm_count}</div>
                    <div className="usage-head-label text-center">{configData.min_scale_vm_count}</div>
                    <div className="usage-head-label text-center">{configData.max_scale_vm_count}</div>
                    <div className="usage-head-label text-center">{configData.max_units}</div>
                    <div className="usage-head-label text-center">{configData.status}</div>
                    <div className="usage-head-label text-center">
                      <button
                        className={
                          configData.status == "Created" && configData.status !== "Deleting"
                            ? "btn btn-sm btn-danger d-inline-block m-auto"
                            : "btn btn-sm btn-danger d-inline-block m-auto disabled "
                        }
                        styles={{
                          cursor: configData.status == "Created" ? "pointer" : "not-allowed"
                        }}
                        type="button"
                        onClick={() => {
                          setDeleteClusterId(configData._id);
                          setDeleteConfirmMsg("you want to delete " + configData.rendercluster_name + "?");
                          setOpenDeleteModal(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                    <div className="pb-stat-icon dropdown">
                      <a className="p-2 pointer" type="" id="" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle r="1.5" transform="matrix(-1 0 0 1 1.5 1.5)" />
                          <circle r="1.5" transform="matrix(-1 0 0 1 1.5 7.5)" />
                          <circle r="1.5" transform="matrix(-1 0 0 1 1.5 13.5)" />
                        </svg>
                      </a>

                      <div className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                        <ul>
                          <li
                            onClick={() => {
                              setResetClusterId(configData._id);
                              setResetConfirmMsg("you want to reset " + configData.rendercluster_name + "?");
                              setOpenResetModal(true);
                            }}
                          >
                            <a className="dropdown-item">
                              <span>Reset Cluster</span>
                            </a>
                          </li>
                          <li
                            onClick={() => {
                              refreshCluster(configData._id);
                            }}
                          >
                            <a className="dropdown-item">
                              <span>Refresh Cluster</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    );
  };

  const createCluster = (image) => {
    setOpenCreateCluster(true);
  };
  const viewClusterUsage = (image) => {
    setOpenClusterUsage(true);
  };
  const viewVmDetails = () => {
    setOpenVmDetails(true);
  };
  return (
    <div className={DisplayMode ? "theme dark" : "theme"}>
      {loading && <Loader />}

      <div className="main-wrapper">
        <Header />
        <div className="template-wrapper pb-wrapper">
          <div className="d-flex">
            <div className="p-2 flex-fill total-template-count">
              <h2 className="h4">Cluster Settings</h2>
            </div>
            <button
              onClick={() => {
                viewVmDetails();
              }}
              type="submit"
              className="btn btn-outline-primary text-nowrap add-user-btn mb-2 me-3"
              value="submit"
              data-bs-toggle="modal"
              data-bs-target="#add-user"
            >
              View VM Details
            </button>
            <button
              onClick={() => {
                viewClusterUsage();
              }}
              type="submit"
              className="btn btn-outline-primary text-nowrap add-user-btn mb-2 me-3"
              value="submit"
              data-bs-toggle="modal"
              data-bs-target="#add-user"
            >
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Start Date"
                className=""
                data-mui-internal-clone-element="true"
              >
                <path d="M1.09277 8.40445H18.9167" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M14.442 12.3088H14.4512" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M10.0045 12.3088H10.0137" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5.55769 12.3088H5.56695" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M14.442 16.1955H14.4512" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M10.0045 16.1955H10.0137" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5.55769 16.1955H5.56695" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M14.0438 1V4.29078" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5.96564 1V4.29078" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.2383 2.58008H5.77096C2.83427 2.58008 1 4.21601 1 7.2231V16.2727C1 19.3271 2.83427 21.0009 5.77096 21.0009H14.229C17.175 21.0009 19 19.3555 19 16.3484V7.2231C19.0092 4.21601 17.1842 2.58008 14.2383 2.58008Z"
                  stroke=""
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              View Cluster Usage
            </button>
            <button
              onClick={() => {
                createCluster();
              }}
              type="submit"
              className="btn btn-outline-primary text-nowrap add-user-btn mb-2"
              value="submit"
              data-bs-toggle="modal"
              data-bs-target="#add-user"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.125 10H16.875" stroke="#C0C2C4" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 3.125V16.875" stroke="#C0C2C4" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Add Cluster
            </button>
          </div>
          {clusterList.length > 0 ? (
            listClusterData(clusterList)
          ) : (
            <div className="no-data">
              <p>No data available</p>
            </div>
          )}
          {/* <div  className="row pagination-holder mb-0 justify-content-center">
              <div  className="col-auto">
                <Pagination
                  onChange={paginate}
                  page={currentPage}
                  shape='rounded'
                  variant="outlined"
                  color="primary"
                  count={Math.ceil(clusterCount/perPage)}
                />
              </div>
            </div> */}
        </div>
      </div>
      <CreateCluster
        closePreview={() => setOpenCreateCluster(false)}
        createUserModal={openCreateCluster}
        setnewUsers={setnewUser}
        // selectedImage={selectedImageFile}
      />
      <ClusterUsage
        closePreview={() => setOpenClusterUsage(false)}
        createUserModal={openClusterUsage}

        // selectedImage={selectedImageFile}
      />
      <VmDetails
        closePreview={() => setOpenVmDetails(false)}
        createUserModal={openvmDetails}

        // selectedImage={selectedImageFile}
      />
      <CustomModal
        isOpen={openDeleteModal}
        Closemodal={setOpenDeleteModal}
        Content={deleteConfirmMsg}
        Header="Are you sure"
        Buttonlabel="Yes"
        Buttonclick={deleteCluster}
        del_id={deleteClusterId}
      />
      <CustomModal
        isOpen={openResetModal}
        Closemodal={setOpenResetModal}
        Content={resetConfirmMsg}
        Header="Are you sure"
        Buttonlabel="Yes"
        Buttonclick={resetCluster}
        del_id={resetClusterId}
      />
    </div>
  );
}

export default ListCluster;
