import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../../store/index";

const AddAudioScript = (props) => {
  let project = useSelector((state) => state.project);
  let ActiveModule = useSelector((state) => state.project.ActiveModule);
  let ActiveSlide = useSelector((state) => state.project.ActiveSlide);
  let ActiveScreen = useSelector((state) => state.project.ActiveScreen);
  let projectID = useSelector((state) => state.project.ProjectId);
  const dispatch = useDispatch();
  let { EditAudioScript } = bindActionCreators(actionCreator, dispatch);

  const [projects, setProjects] = useState("");
  const [audioScript, setAudioScript] = useState("");
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [audioValidation, setAudioValidation] = useState("");

  useEffect(() => {
    setProjectId(projectID);
    setProjects(project);
    if (project.createdProject[projectID] != null) {
      setProjectLoaded(true);
    }
  }, [project, projectID, ActiveSlide]);

  useEffect(() => {
    if (audioValidation) {
      setTimeout(() => {
        setAudioValidation(false);
      }, 3000);
    }
  }, [audioValidation]);

  useEffect(() => {
    if (projectLoaded) {
      if (project.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            Object.entries(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).map((item, key) => {
              if (item[1].Contenttype == "AudioScript") {
                let data = item[1].Contentparams.contentdescription;
                if (data !== undefined) {
                  setAudioScript(data);
                } else {
                  setAudioScript("");
                }
              }
            });
          }
        }
      }
    }
  }, [ActiveScreen, projects]);

  const addAudioScript = (event) => {
    let value = event.target.value;
    // const regMatch = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(value);
    const regMatch = /^[\.a-zA-Z,' ]*$/.test(value);
    if (regMatch === false) {
      setAudioValidation(true);
    } else {
      setAudioValidation(false);
      EditAudioScript({
        sequenceid: "2",
        contentdescription: event.target.value,
        contenttag: ""
      });
      setAudioScript(event.target.value);
    }
  };


  return (
    <div className="cm-audscript-blk">
      <div className="d-flex align-items-center justify-content-between panel-label flex-wrap ">
        <div className="d-flex align-items-center panel-label mb-3">
          Audio Script
          <div className="info-icon ps-2">
            <Tooltip title="Add audio script" arrow>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                  fill="#C0C2C4"
                />
              </svg>
            </Tooltip>
          </div>
          <i style={{ fontStyle: "italic", fontSize: "12px" }}>
            The audio script and OST need to be almost the same(maximum 200 characters are allowed)
          </i>
        </div>

        {(props?.errorScreen?.some(
          (item) => item?.activeModule === ActiveModule && item?.activeSlide === ActiveSlide && item?.activeScreen === ActiveScreen
        ) ||
          props?.templateCheckArray?.some(
            (item) =>
              item?.templateKeys === ActiveModule &&
              item?.templateSlidekeys === ActiveSlide &&
              item?.templateScreenkeys === ActiveScreen &&
              !item?.isValid
          )) && <p className="mb-3 input-error-msg">Template not available</p>}
      </div>
      <div className="panel-txtarea-blk">
        <textarea value={audioScript} onChange={addAudioScript} className="panel-txtarea" placeholder="Enter Audio Script"></textarea>
      </div>
      {audioValidation ? (
        <div name="courseName" component="div" className="input-error-msg">
          Accepts only alphabetic characters
        </div>
      ) : null}
      {props?.activeAudioScriptComparisonResultsState?.some(
        (item) => item?.activeModule === ActiveModule && item?.activeSlide === ActiveSlide && item?.activeScreen === ActiveScreen && !item?.isValid
      ) && <p className="input-error-msg">80% characters should match with OST</p>}
      {props?.invalidAudioScreens?.some(
        (item) => `${ActiveModule} ${ActiveSlide} ${ActiveScreen}` === `${item?.activeModule} ${item?.activeSlide} ${item?.activeScreen}`
      ) && <p className="input-error-msg">Audio script is required</p>}
    </div>
  );
};
export default AddAudioScript;
