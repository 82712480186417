import React, { useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { useDispatch, useSelector } from "react-redux";

function CustomModal(props) {
    let DisplayMode = useSelector(state => state.project.DisplayMode)
    useEffect(() => {
        
    }, [props])
    return (
        <Modal
            isOpen={props.isOpen}
            fade={true}
            centered
            className={DisplayMode?"modal-dialog  modal-dialog-centered custom-modal delete-modal dark modal-md":"modal-dialog  modal-dialog-centered custom-modal delete-modal modal-md"}
            // className="modal-dialog  modal-dialog-centered custom-modal delete-modal dark modal-md"
        >
            <ModalBody>

                <div className="modal-body p-4">
                    <div className="modal-close text-right" title="Close" data-bs-dismiss="modal" onClick={() => {props.Closemodal(false)}}>
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.7487 15.5463C16.9095 15.7059 17 15.9231 17 16.1497C17 16.3763 16.9095 16.5935 16.7487 16.7531C16.5892 16.9139 16.3721 17.0044 16.1456 17.0044C15.9191 17.0044 15.7019 16.9139 15.5424 16.7531L8.5 9.69972L1.45758 16.7531C1.29807 16.9139 1.08094 17.0044 0.854433 17.0044C0.627923 17.0044 0.410792 16.9139 0.251283 16.7531C0.0904604 16.5935 0 16.3763 0 16.1497C0 15.9231 0.0904604 15.7059 0.251283 15.5463L7.3022 8.5015L0.251283 1.45666C0.035798 1.2411 -0.0483584 0.926912 0.0305146 0.632452C0.109387 0.337993 0.339307 0.107994 0.633665 0.0290942C0.928023 -0.049806 1.2421 0.0343795 1.45758 0.249939L8.5 7.30328L15.5424 0.249939C15.8755 -0.083287 16.4156 -0.083287 16.7487 0.249939C17.0818 0.583164 17.0818 1.12343 16.7487 1.45666L9.69781 8.5015L16.7487 15.5463Z" fill="" />
                        </svg>
                    </div>
                    <h3>{props.Header}</h3>
                    {
                        props.Content!==undefined&&props.Content!==null&&
                        props.Content.map((ostarray,index)=>{
                        return(
                            <p>{index+1}. {ostarray } is Required</p>
                        )
                        })
                    }
                  
                    <button type="button" className="btn btn-primary " onClick={() => {
                        if(props.del_id != undefined){
                            props.Buttonclick(props.del_id)
                            props.Closemodal(false)
                        }else{
                            props.Buttonclick()
                            props.Closemodal(false)
                        }
                        }}>{props.Buttonlabel}</button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default CustomModal
