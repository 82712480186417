import { io } from "socket.io-client";
import { getUserItem } from "./helper";

export const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket"] });

export function joinUserToRoom() {
  try {
    const userId = getUserItem("id");
    if (socket.connected && userId) {
      socket.emit('userjoin', userId);
    }
  } catch (e) {
    console.log(e);
  }
}